import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentCalendar'
})
export class CalendarPipe implements PipeTransform {
  transform(value: number, curFormat = 'YYYYMMDDHHmmss'): any {
    if (value === 0) {
      return 'Never';
    }
    return moment(value, curFormat).calendar(null, {
      sameDay: '[Today] [at] LT',
      lastDay: '[Yesterday] [at] LT',
      nextDay: '[Tomorrow] [at] LT',
      nextWeek: '[Next] dddd [at] LT',
      lastWeek: 'dddd [at] LT',
      sameElse: 'MM/DD/YYYY [at] LT'
    });
  }
}
