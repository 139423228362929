import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'home',
    link: '/dashboard',
    home: true,
  },
  {
    title: 'Stores',
    icon: 'cart-plus',
    link: '/stores'
  },
  {
    title: 'Signup',
    icon: 'bell',
    link: '/signup'
  },
  {
    title: 'Billing',
    icon: 'file-invoice-dollar',
    link: '/billing'
  },
  {
    title: 'Alerts',
    icon: 'bell',
    link: '/alerts'
  }
];

export const THEMES = [
  {
    value: 'default',
    name: 'Light',
  },
  {
    value: 'dark',
    name: 'Dark',
  },
];
