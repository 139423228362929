
    <nb-card>
      <nb-card-header>
        <div cdkFocusInitial class="title" tabindex="-1">{{ config.title }}</div>

        <div class="buttons">
<!--          <button nbButton ghost *ngIf="!minimized" (click)="minimize()">-->
<!--            <nb-icon icon="minus-outline" pack="nebular-essentials"></nb-icon>-->
<!--          </button>-->
<!--          <button nbButton ghost *ngIf="minimized" (click)="maximizeOrFullScreen()">-->
<!--            <nb-icon icon="expand-outline" pack="nebular-essentials"></nb-icon>-->
<!--          </button>-->
          <button nbButton ghost (click)="close()">
            <nb-icon icon="close-outline" pack="nebular-essentials"></nb-icon>
          </button>
        </div>
      </nb-card-header>
      <nb-card-body *ngIf="isFullScreen">
        <nb-overlay-container></nb-overlay-container>
      </nb-card-body>
    </nb-card>
  