import {NgModule} from '@angular/core';
import {DashboardComponent} from '@modules/dashboard/page/dashboard.component';
import {SharedModule} from '@shared/shared.module';
import {SharedComponentsModule} from '@shared/components/shared-components.module';
import {PipeModule} from '@shared/pipes/pipe.module';

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    SharedModule,
    SharedComponentsModule,
    PipeModule,
  ]
})
export class DashboardModule {
}
