<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="bars"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">DEALER <span> CURATOR</span></a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="control-item" icon="envelope-open-text"></nb-action>
    <nb-action class="control-item" icon="bell"></nb-action>
    <nb-action class="user-action">
      <nb-user
        [nbContextMenu]="userMenu"
        nbContextMenuTag="header-menu"
        shape="semi-round"
        [onlyPicture]="userPictureOnly"
        [name]="user?.name">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
