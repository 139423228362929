import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  Renderer2,
  ViewChild,
  Type,
  ComponentFactoryResolver,
  Input,
  AfterViewChecked,
} from '@angular/core';
import { NB_WINDOW_CONTENT, DlrWindowConfig, NbWindowState, NB_WINDOW_CONTEXT } from './window.options';
import { DlrWindowRef } from './window-ref';
import {
  NbComponentPortal,
  NbComponentType,
  NbFocusTrap,
  NbFocusTrapFactoryService,
  NbOverlayContainerComponent,
  NbTemplatePortal
} from '@nebular/theme';

@Component({
  selector: 'dlr-window',
  template: `
    <nb-card>
      <nb-card-header>
        <div cdkFocusInitial class="title" tabindex="-1">{{ config.title }}</div>

        <div class="buttons">
<!--          <button nbButton ghost *ngIf="!minimized" (click)="minimize()">-->
<!--            <nb-icon icon="minus-outline" pack="nebular-essentials"></nb-icon>-->
<!--          </button>-->
<!--          <button nbButton ghost *ngIf="minimized" (click)="maximizeOrFullScreen()">-->
<!--            <nb-icon icon="expand-outline" pack="nebular-essentials"></nb-icon>-->
<!--          </button>-->
          <button nbButton ghost (click)="close()">
            <nb-icon icon="close-outline" pack="nebular-essentials"></nb-icon>
          </button>
        </div>
      </nb-card-header>
      <nb-card-body *ngIf="isFullScreen">
        <nb-overlay-container></nb-overlay-container>
      </nb-card-body>
    </nb-card>
  `,
  styleUrls: ['./window.component.scss'],
})
export class DlrWindowComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() cfr: ComponentFactoryResolver;

  @HostBinding('class.full-screen')
  get isFullScreen() {
    return this.windowRef.state === NbWindowState.FULL_SCREEN;
  }

  @HostBinding('class.minimized')
  get minimized() {
    return this.windowRef.state === NbWindowState.MINIMIZED;
  }

  @ViewChild(NbOverlayContainerComponent) overlayContainer: NbOverlayContainerComponent;

  protected focusTrap: NbFocusTrap;

  constructor(
    @Inject(NB_WINDOW_CONTENT) public content: TemplateRef<any> | NbComponentType,
    // tslint:disable-next-line:ban-types
    @Inject(NB_WINDOW_CONTEXT) public context: Object,
    public windowRef: DlrWindowRef,
    public config: DlrWindowConfig,
    protected focusTrapFactory: NbFocusTrapFactoryService,
    protected elementRef: ElementRef,
    protected renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.focusTrap = this.focusTrapFactory.create(this.elementRef.nativeElement);
    this.focusTrap.blurPreviouslyFocusedElement();
    this.focusTrap.focusInitialElement();

    if (this.config.windowClass) {
      this.renderer.addClass(this.elementRef.nativeElement, this.config.windowClass);
    }
  }

  ngAfterViewChecked() {
    if (!this.overlayContainer || this.overlayContainer.isAttached) {
      return;
    }

    if (this.content instanceof TemplateRef) {
      this.attachTemplate();
    } else {
      this.attachComponent();
    }
  }

  ngOnDestroy() {
    if (this.focusTrap) {
      this.focusTrap.restoreFocus();
    }

    this.close();
  }

  minimize() {
    this.windowRef.minimize();
  }

  maximizeOrFullScreen() {
    this.windowRef.fullScreen();
  }

  close() {
    this.windowRef.close();
  }

  protected attachTemplate() {
    this.overlayContainer
      .attachTemplatePortal(new NbTemplatePortal(this.content as TemplateRef<any>, null, this.context));
  }

  protected attachComponent() {
    const portal = new NbComponentPortal(this.content as Type<any>, null, null, this.cfr);
    const ref = this.overlayContainer.attachComponentPortal(portal, this.context);
    ref.changeDetectorRef.detectChanges();
  }
}
