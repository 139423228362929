import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFirestore} from '@angular/fire/firestore';
import {Store} from '@ngxs/store';
import {environment} from '@env';
import Stripe = stripe.Stripe;

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  headers: HttpHeaders;
  apiUrl: string;

  constructor(private databaseService: AngularFireDatabase, private firestore: AngularFirestore,
              private http: HttpClient, private store: Store) {
    this.apiUrl = environment.apiUrl;
  }

  getUrl(url) {
    return `${this.apiUrl}/${url}`;
  }

  getAllPrices() {
    return this.http.get(this.getUrl('billing/get-all-prices'))
      .toPromise();
  }

  getAllInvoices() {
    return this.http.get(this.getUrl('billing/get-all-invoices'))
      .toPromise();
  }

  handleError(error: any): void {
    if (!error.response || !error.response.data || !error.response.data) {
      throw {message: 'Unknown Error'};
    }
    throw error.response.data.error || error.response.data.message;
  }
}
