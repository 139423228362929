import {NgModule} from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {SharedComponentsModule} from '@shared/components/shared-components.module';
import {PipeModule} from '@shared/pipes/pipe.module';
import {NbBadgeModule, NbRadioModule} from '@nebular/theme';
import {ClipboardModule} from 'ngx-clipboard';
import {DlrWindowModule} from '../../theme/components/window/window.module';
import {StoresComponent} from "@modules/stores/page/stores.component";
import { OrgCardComponent } from './components/org-card/org-card.component';
import { StoresCardComponent } from './components/stores-card/stores-card.component';
import { EditStoreImageLogicComponent } from './components/edit-store-image-logic/edit-store-image-logic.component';
import {NzEmptyModule} from "ng-zorro-antd";

@NgModule({
  entryComponents: [StoresComponent, EditStoreImageLogicComponent],
  declarations: [StoresComponent, OrgCardComponent, StoresCardComponent, EditStoreImageLogicComponent],
  imports: [
    SharedModule,
    SharedComponentsModule,
    PipeModule,
    NbRadioModule,
    ClipboardModule,
    DlrWindowModule.forChild(),
    NbBadgeModule,
    NzEmptyModule
  ]
})
export class StoresModule {
}
