<nb-card class="token-card" [status]="tokenInfo.isUsed ? 'danger' : 'success'"
         [accent]="tokenInfo.isUsed ? 'danger' : 'success'">
  <nb-card-body class="token-card-body">

    <div class="row body-row">
      <div class="col-sm-7">
        <h6 class="font-weight-bold">{{tokenInfo.name}}</h6>
        <p class="font-weight-bold" [class.text-success]="!tokenInfo.isUsed" [class.text-danger]="tokenInfo.isUsed">
          {{tokenInfo.isUsed ? "Token was activated!" : "Token is inactive!"}}
        </p>
      </div>
      <div class="col-sm-5">
        <span><strong>Created for:</strong> {{tokenInfo.email}}</span>
        <p *ngIf="tokenInfo.userID"><strong>Activated by:</strong> {{tokenInfo.userID}}</p>
      </div>
    </div>
    <div class="row body-row">
      <div class="col-sm-7">
        <span>Created: <strong>{{tokenInfo.createdAt | momentCalendar}}</strong></span>
        <p *ngIf="tokenInfo.updatedAt">Last Updated: <strong>{{tokenInfo.updatedAt | momentCalendar}}</strong></p>
        <p *ngIf="tokenInfo.usedAt">Used on: <strong>{{tokenInfo.usedAt | momentCalendar}}</strong></p>
      </div>
      <div class="col-sm-5">
        <span *ngIf="tokenInfo.orgID"><strong>Belongs to:</strong> {{tokenInfo.orgID}} <strong>ORG</strong></span>
      </div>
    </div>

    <div class="right-container">
      <nb-actions size="medium" class="token-info-icon">
        <nb-action icon="trash-alt" title="Delete Token" *ngIf="!tokenInfo.isUsed"
                   (click)="deleteToken()" class="delete-action">
        </nb-action>
        <nb-action icon="edit" title="Edit Token"
                   (click)="editTokenInfo()" class="edit-action">
        </nb-action>
        <nb-action ngxClipboard [cbContent]="buildCopyContent(tokenInfo.tokenID)" nbTooltipTrigger="hover" icon="copy"
                   (cbOnSuccess)="copiedText()" nbTooltip="Copy Token Signup Link to Clipboard">
        </nb-action>
      </nb-actions>
    </div>
  </nb-card-body>
</nb-card>
