
export enum ActionTypes {
  GET_ALL_TOKENS = '[SignUp] GetSignupTokens',
  ADD_SIGNUP_TOKEN = '[SignUp] AddSignUpToken',
  EDIT_SIGNUP_TOKEN = '[SignUp] EditSignUpToken',
}

export class GetAllTokens {
  static readonly type = ActionTypes.GET_ALL_TOKENS;
  constructor() {}
}

export class AddSignupToken {
  static readonly type = ActionTypes.ADD_SIGNUP_TOKEN;
  constructor(public payload: any) {}
}

export class EditSignupToken {
  static readonly type = ActionTypes.EDIT_SIGNUP_TOKEN;
  constructor(public payload: any) {}
}
