import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFirestore} from '@angular/fire/firestore';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@env';
import {AuthService} from '@app/service/auth.service';
import {map, take} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  headers: HttpHeaders;
  apiUrl: string;
  userID: string;
  userInfoPath: string;

  constructor(private databaseService: AngularFireDatabase, private firestore: AngularFirestore,
              private http: HttpClient, private authService: AuthService) {
    this.apiUrl = environment.apiUrl;
    this.userInfoPath = `users`;
  }

  getUserData(userID) {
    if (!userID) {
      return of({message: 'You are not authenticated, please refresh the page!'});
    }
    return this.firestore.collection('superusers').doc(userID).snapshotChanges().pipe(
      take(1), // If the reads start getting expensive
      map(document => {
        const userData: any = document.payload.data();
        const uid = document.payload.id;
        return {
          id: uid,
          ...userData
        };
      })
    );
  }

  getUrl(url) {
    return `${this.apiUrl}/${url}`;
  }
}
