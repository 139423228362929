
export enum ActionTypes {
  GET_ALL_PRICES = '[Billing] GetAllPrices',
  GET_INVOICES = '[Billing] GetInvoices',
}

export class GetAllPrices {
  static readonly type = ActionTypes.GET_ALL_PRICES;
  constructor() {}
}

export class GetInvoices {
  static readonly type = ActionTypes.GET_INVOICES;
  constructor() {}
}
