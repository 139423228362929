import {CuratorAlert} from '@shared/model';
import * as firebase from 'firebase/app';
import 'firebase/auth';

export enum ActionTypes {
  GET_USER = '[Auth] GetUser',
  CHECK_SESSION = '[Auth] CheckSession',
  LOGIN = '[Auth] Login',
  LOGIN_SUCCESS = '[Auth] LoginSuccess',
  LOGIN_FAILURE = '[Auth] LoginFailure',
  LOGIN_REDIRECT = '[Auth] LoginRedirect',
  LOGOUT = '[Auth] Logout',
  LOGOUT_SUCCESS = '[Auth] LogoutSuccess',
  REFRESH_TOKEN = '[Auth] RefreshToken'
}

export class GetUser {
  static readonly type = ActionTypes.GET_USER;

  constructor() {
  }
}

export class CheckSession {
  static readonly type = ActionTypes.CHECK_SESSION;

  constructor() {
  }
}

export class Login {
  static readonly type = ActionTypes.LOGIN;

  constructor() {
  }
}

export class LoginSuccess {
  static readonly type = ActionTypes.LOGIN_SUCCESS;

  constructor(public payload: firebase.User) {
  }
}

export class LoginFailure {
  static readonly type = ActionTypes.LOGIN_FAILURE;

  // Payload is error object
  constructor(public payload: CuratorAlert | any) {
  }
}

export class LoginRedirect {
  static readonly type = ActionTypes.LOGIN_REDIRECT;

  constructor(public redirectUrl: any) {
  }
}

export class Logout {
  static readonly type = ActionTypes.LOGOUT;

  constructor() {
  }
}

export class LogoutSuccess {
  static readonly type = ActionTypes.LOGOUT_SUCCESS;

  constructor() {
  }
}

export class RefreshToken {
  static readonly type = ActionTypes.REFRESH_TOKEN;

  constructor() {
  }
}
