import {AlertType} from '@shared/model/alerts';
import {StoreFeed} from '@shared/model/feed';
import {StoreData} from '@shared/model/store';

export interface Address {
  address: string;
  city: string;
  state: string;
  zipcode: string;
}

export interface Service {
  platform: string;
  serviceID: string;
  metadata: {
    facebookAdAccountID: string;
    facebookPixelID: string;
    facebookPageID: string;
    adwordsConversionID: string;
    adwordsCustomerID: string;
    adwordsDynamicFeedID: string;
  };
}

export enum Role {
  superuser = 'superuser',
  owner = 'owner',
  admin = 'admin',
  user = 'user',
  readonly = 'readonly'
}

export interface ServerResponse {
  redirectUrl?: string;
  message: string;
  error?: string;
  data?: any;
  type: AlertType;
}

export interface StoreTreeNode {
  data: StoreData;
  children?: {data: StoreFeed}[];
  expanded?: boolean;
}

export interface GridTreeNode<T> {
  data: T;
  children?: GridTreeNode<T>[];
  expanded?: boolean;
}
