import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {AuthService} from '@app/service/auth.service';
import {RoutingService} from '@app/service/routing.service';
import {LoggerService} from '@app/service/logger/logger.service';
import {Login} from '@shared/state/auth/auth.actions';
import {Store} from '@ngxs/store';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'dlr-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', './../auth.scss']
})
export class LoginComponent implements OnInit {
  messages: string[];
  returnUrl: string;
  submitted: boolean;
  loading = false;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private logger: LoggerService,
              private authService: AuthService, private router: RoutingService, private store: Store) {
    this.messages = [];
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';
    this.submitted = false;
  }

  ngOnInit(): void {
  }

  login(): any {
    this.messages = [];
    this.submitted = true;

    this.loading = true;
    return this.store.dispatch(new Login())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        this.loading = false;
        this.submitted = false;
      });
  }

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
