import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NebularModule } from './nebular.module';
import {MomentModule} from 'ngx-moment';
import {ThemeModule} from '../theme/theme.module';
import {MatButtonModule} from '@angular/material/button';
import {NgxsModule} from '@ngxs/store';
import {LoadingComponent} from '@shared/components/loading/loading.component';
import {PipeModule} from '@shared/pipes/pipe.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NebularModule,
    MomentModule,
    ThemeModule,
    MatButtonModule,
    PipeModule
  ],
  declarations: [],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NebularModule,
    MomentModule

  ]
})
export class SharedModule {}
