import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'dlr-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  date: string;

  constructor() {
    this.date = moment().format('YYYY');
  }

  ngOnInit(): void {
  }

}
