import {Injectable, OnDestroy} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFireAuth} from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import 'firebase/messaging';

import {BehaviorSubject, Subject} from 'rxjs';
import {ToastService} from '@app/service/util/toast.service';
import {mergeMap, mergeMapTo, take, takeUntil} from 'rxjs/operators';
import {AngularFireMessaging} from "@angular/fire/messaging";
import {Store} from "@ngxs/store";
import {AuthState} from "@shared/state/auth/auth.state";
import {LoggerService} from "@app/service/logger/logger.service";

@Injectable({
  providedIn: 'root'
})
export class MessagingService implements OnDestroy{

  messaging = firebase.messaging();
  currentMessage = new BehaviorSubject(null);

  constructor(private db: AngularFireDatabase, private afAuth: AngularFireAuth, private toastService: ToastService,
              private msg: AngularFireMessaging, private store: Store, private logger: LoggerService) {
    // if (this.messaging) {
    //   this.messaging.onTokenRefresh(() => {
    //     this.getPermission();
    //   });
    // }
  }

  requestPermission() {
    this.msg.requestPermission
      .pipe(mergeMapTo(this.msg.tokenChanges))
      .subscribe(
        (token) => {
          const userID = this.store.selectSnapshot(AuthState.getUserID);
          return this.db.object(`users/${userID}`).update({fcmToken: token});
        },
        (error) => {
          this.logger.error(error);
        },
      );
  }

  deleteToken() {
    this.msg.getToken
      .pipe(mergeMap(token => this.msg.deleteToken(token)), takeUntil(this.ngUnsubscribe))
      .subscribe(
        (token) => {
          this.logger.info('Token deleted!');
        },
      );
  }

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  listen() {
    this.msg.messages
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((message: any) => {
        const notification = message.notification;
        const data = message.data;
        this.toastService.response(data.type, notification.body, notification.title, data.duration);
      });
  }

  // updateToken(token) {
  //   this.afAuth.authState
  //     .pipe(take(1))
  //     .subscribe(user => {
  //       if (!user) {
  //         return;
  //       }
  //       return this.db.object(`users/${user.uid}`).update({fcmToken: token});
  //     });
  // }
  //
  // getPermission() {
  //   this.messaging.usePublicVapidKey(environment.vapidKey);
  //   this.messaging.requestPermission()
  //     .then(() => {
  //       return this.messaging.getToken();
  //     })
  //     .then(token => {
  //       this.updateToken(token);
  //     })
  //     .catch((err) => {
  //       console.log('Unable to get permission to notify.', err);
  //     });
  // }
  //
  // receiveMessage() {
  //   this.messaging.onMessage((payload) => {
  //     const notification = payload.notification;
  //     const data = payload.data;
  //     this.toastService.response(data.type, notification.body, notification.title, data.duration);
  //     this.currentMessage.next(payload);
  //   });
  // }
}
