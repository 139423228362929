import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import {TokenInterceptor} from './interceptor/token.interceptor';
import {AuthStateGuard} from './guard/auth-state.guard';
import {AuthGuard} from './guard/auth.guard';
import {throwIfAlreadyLoaded} from './guard/module-import.guard';
import {AnalyticsService} from '@app/service/util/analytics.service';
import {LoggerService} from '@app/service/logger/logger.service';
import {LogPublishersService} from '@app/service/logger/log-publishers.service';


@NgModule({
  imports: [
    HttpClientModule,
    // TODO: Import a Spinner
  ],
  providers: [
    AuthGuard,
    AuthStateGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AnalyticsService,
    LoggerService,
    LogPublishersService
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
