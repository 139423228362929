import {NgModule} from '@angular/core';
import {Routes, RouterModule, ExtraOptions} from '@angular/router';
import {DashboardComponent} from '@modules/dashboard/page/dashboard.component';
import {SignupComponent} from "@modules/signup/page/signup.component";
import {StoresComponent} from "@modules/stores/page/stores.component";

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'stores',
    component: StoresComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'billing',
    component: DashboardComponent
  },
  {
    path: 'alerts',
    component: DashboardComponent
  }
];

const config: ExtraOptions = {
  useHash: false,
  // enableTracing: environment.local
};

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContentRoutingModule {
}
