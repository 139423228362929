import { Injectable } from '@angular/core';
import {LogPublishersService} from './log-publishers.service';
import {LogPublisher} from '@app/service/logger/publishers';
import * as moment from 'moment';

export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5,
  Off = 6
}

@Injectable()
export class LoggerService {
  publishers: LogPublisher[];
  level: LogLevel = LogLevel.All;
  logWithDate = true;

  constructor(private publishersService: LogPublishersService) {
    // Set publishers
    this.publishers = this.publishersService.publishers;
  }

  clear(): void {
    for (const logger of this.publishers) {
      logger.clear()
        .subscribe(response => console.log(response));
    }
  }

  debug(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  info(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
  }

  warn(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Warn, optionalParams);
  }

  error(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Error, optionalParams);
  }

  fatal(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Fatal, optionalParams);
  }

  log(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.All, optionalParams);
  }

  // *************************
  // Private methods
  // *************************
  private shouldLog(level: LogLevel): boolean {
    let ret = false;

    if ((level >= this.level &&
      level !== LogLevel.Off) ||
      this.level === LogLevel.All) {
      ret = true;
    }

    return ret;
  }

  private writeToLog(msg: string, level: LogLevel, params: any[]) {
    if (this.shouldLog(level)) {
      let value = '';

      // Build log string
      if (this.logWithDate) {
        value = moment(new Date()).format('ddd, MMM DD YYYY, hh:mm:ss a') + ' - ';
      }
      value += 'Type: ' + LogLevel[this.level];
      value += ' - Message: ' + msg;
      if (params.length) {
        value += ' - Extra Info: '
          + this.formatParams(params);
      }

      // Log the value
      console.log(value);
    }
  }

  private formatParams(params: any[]): string {
    let ret: string = params.join(',');

    // Is there at least one object in the array?
    if (params.some(p => typeof p === 'object')) {
      ret = '';
      // Build comma-delimited string
      for (const item of params) {
        // Note: cache should not be re-used by repeated calls to JSON.stringify.
        let cache = [];
        ret += JSON.stringify(item, (key, value) => {
          if (typeof value === 'object' && value !== null) {
            if (cache.indexOf(value) !== -1) {
              // Duplicate reference found, discard key
              return;
            }
            // Store value in our collection
            cache.push(value);
          }
          return value;
        }) + ',';
        cache = null; // Enable garbage collection
      }
    }

    return ret;
  }
}
