import { Injectable } from '@angular/core';
import {NbToastrService} from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  duration = 7000;
  preventDuplicates = true;

  constructor(private toastr: NbToastrService) {
  }

  response(type, title, message?, duration = this.duration) {
    this.toastr[type](message, title, parseInt(duration.toString(), 10));
  }

  success(title, message?, duration = this.duration) {
    this.toastr.success(message, title, {
      duration,
      preventDuplicates: this.preventDuplicates
    });
  }

  error(title, message?, duration = this.duration) {
    this.toastr.danger(message, title, {
      duration,
      preventDuplicates: this.preventDuplicates
    });
  }

  warning(title, message?, duration = this.duration) {
    this.toastr.warning(message, title, {
      duration,
      preventDuplicates: this.preventDuplicates
    });
  }

  info(title, message?, duration = this.duration) {
    this.toastr.info(message, title, {
      duration,
      preventDuplicates: this.preventDuplicates
    });
  }

  show(title, message?, duration = this.duration) {
    this.toastr.show(message, title, {
      duration,
      preventDuplicates: this.preventDuplicates
    });
  }
}
