<div class="row">
  <div class="col-lg-7 col-md-12" *ngIf="true">
    <nb-card class="summary-card">
      <nb-card-header>
        <h6 class="font-weight-bold">Store Info</h6>
      </nb-card-header>
      <nb-card-body class="summary-card-body">
      </nb-card-body>
    </nb-card>
  </div>
</div>
