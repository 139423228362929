import { NgModule } from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {ContentLayoutComponent} from './content-layout/content-layout.component';
import {AuthLayoutComponent} from './auth-layout/auth-layout.component';
import {NebularModule} from '@shared/nebular.module';
import {NbAuthModule} from '@nebular/auth';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import {NbActionsModule, NbContextMenuModule, NbMenuModule, NbSidebarModule, NbUserModule} from '@nebular/theme';
import {PipeModule} from '@shared/pipes/pipe.module';

@NgModule({
  declarations: [ContentLayoutComponent, AuthLayoutComponent, FooterComponent, HeaderComponent],
  imports: [
    SharedModule,
    NebularModule,
    NbAuthModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbActionsModule,
    NbUserModule,
    NbContextMenuModule,
    PipeModule
  ],
  providers: [
  ]
})
export class LayoutModule { }
