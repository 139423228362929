<nb-card class="token-card">
  <nb-card-body class="token-card-body">
    <div class="row body-row">
      <div class="col-sm-12">
        <h6 class="font-weight-bold">{{orgInfo.name}} •• {{orgInfo.id}}</h6>
        <p class="mb-2"><strong>Stripe Customer:</strong> {{orgInfo.stripeCustomerID}}<strong>&emsp;SUB ID:</strong> {{orgInfo.subID}}</p>
        <p class="mb-2">
          <strong>SignUp Token:</strong> {{orgInfo.signUpToken}}
          <span class="mb-2" *ngIf="orgInfo.fbBusinessID">
            <strong>&emsp;FB Bus ID:</strong> {{orgInfo.fbBusinessID}}
          </span>
        </p>
        <p class="mb-2">
          Created: <strong>{{orgInfo.createdAt | momentCalendar}}</strong>
          <span *ngIf="orgInfo.updatedAt">&emsp;Updated: <strong>{{orgInfo.updatedAt | momentCalendar}}</strong>
          </span>
        </p>
        <div class="row body-row">
          <div class="col-sm-6">
            <p class="mb-1"><strong>Alarms:</strong> {{orgInfo.counts.alarms}}</p>
            <p class="mb-1"><strong>Alerts:</strong> {{orgInfo.counts.alerts}}</p>
          </div>
          <div class="col-sm-6">
            <p class="mb-1"><strong>Feeds:</strong> {{orgInfo.counts.feeds}}</p>
            <p class="mb-1"><strong>Stores:</strong> {{orgInfo.counts.stores}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="right-container">
      <nb-actions size="medium" class="token-info-icon">
        <nb-action icon="edit" title="Edit Team"
                   (click)="editTeamInfo()" class="edit-action">
        </nb-action>
        <nb-action ngxClipboard [cbContent]="orgInfo.id" nbTooltipTrigger="hover" icon="copy"
                   (cbOnSuccess)="copiedText()" nbTooltip="Copy ORG ID to Clipboard">
        </nb-action>
      </nb-actions>
      <button nbButton status="primary" size="small" class="signup-add-btn" (click)="selectOrg()">
        Select Team
      </button>
    </div>
  </nb-card-body>
</nb-card>
