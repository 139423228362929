import {Injectable} from '@angular/core';
import {CanActivate, UrlTree, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '@app/service/auth.service';
import {RoutingService} from '@app/service/routing.service';
import {LoggerService} from '@app/service/logger/logger.service';
import {AuthState} from '@shared/state/auth/auth.state';
import {LoginRedirect} from '@shared/state/auth/auth.actions';
import {Store} from '@ngxs/store';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private routeService: RoutingService, private store: Store,
              private authService: AuthService, private logger: LoggerService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.selectOnce(AuthState.getUserToken).pipe(
      map(token => {
        if (!token) {
          this.store.dispatch(new LoginRedirect(state.url));
          return false;
        }
        return true;
      })
    );
  }
}
