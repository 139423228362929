import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from '@env';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => {
    // if ('serviceWorker' in navigator && environment.production) {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('./firebase-messaging-sw.js');
        if (environment.production) {
          navigator.serviceWorker.register('./ngsw-worker.js');
        }
      });
    }
  })
  .catch(err => console.error(err));
