import { NgModule } from '@angular/core';

import { SharedComponentsRouting } from './shared-components.routing';
import {SharedModule} from '@shared/shared.module';
import {NbPopoverModule, NbStepperModule} from '@nebular/theme';
import {LoadingComponent} from '@shared/components/loading/loading.component';
import {ClipboardModule} from 'ngx-clipboard';


@NgModule({
  entryComponents: [],
  declarations: [LoadingComponent],
  exports: [
    LoadingComponent
  ],
  imports: [
    SharedComponentsRouting,
    SharedModule,
    NbStepperModule,
    NbPopoverModule,
    ClipboardModule,
  ]
})
export class SharedComponentsModule { }
