import {NgModule} from '@angular/core';
import {CapitalizePipe} from '@shared/pipes/capitalize.pipe';
import {ShortenPipe} from '@shared/pipes/shorten.pipe';
import {IconifyPipe} from '@shared/pipes/iconify.pipe';
import {CalendarPipe} from '@shared/pipes/moment/calendar.pipe';
import {DateFormatterPipe} from '@shared/pipes/moment/date-formatter.pipe';
import {FromNowPipe} from '@shared/pipes/moment/from-now.pipe';
import {HasPermissionPipe} from '@shared/pipes/has-permission.pipe';
import {MoneyPipe} from '@shared/pipes/money.pipe';
import {TimeSincePipe} from '@shared/pipes/moment/time-since.pipe';
import {ZeroifyPipe} from '@shared/pipes/zeroify.pipe';
import {PricingPlanPipe} from '@shared/pipes/pricing-plan.pipe';
import {DeCamelcasePipe} from "@shared/pipes/de-camelcase.pipe";

@NgModule({
  exports: [
    ShortenPipe,
    CalendarPipe,
    IconifyPipe, MoneyPipe, DeCamelcasePipe,
    HasPermissionPipe, ZeroifyPipe, PricingPlanPipe
  ],
  declarations: [
    CapitalizePipe, ShortenPipe,
    DateFormatterPipe, CalendarPipe,
    FromNowPipe, IconifyPipe, DeCamelcasePipe,
    HasPermissionPipe, MoneyPipe, TimeSincePipe, ZeroifyPipe, PricingPlanPipe
  ]
})

export class PipeModule {
}
