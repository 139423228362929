import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFirestore} from '@angular/fire/firestore';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@env';
import {map} from 'rxjs/operators';
import {Store} from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class SignupService {
  headers: HttpHeaders;
  apiUrl: string;

  constructor(private databaseService: AngularFireDatabase, private firestore: AngularFirestore,
              private http: HttpClient, private store: Store) {
    this.apiUrl = environment.apiUrl;
  }

  /**
   * Subscribe to the stores a user has access to. If there is a change to the use r's store access, role or anything in this path,
   * immediately reflect that for the user
   */
  getSignUpsData() {
    const signupPath = `signUpAccess`;
    return this.firestore.collection<any>(signupPath).snapshotChanges().pipe(
      // take(1), // If the reads start getting expensive
      map(documents => {
        const signUpArray: any[] = [];
        documents.forEach(doc => {
          const signup = doc.payload.doc.data();
          const signupID = doc.payload.doc.id;
          signUpArray.push({
            id: signupID,
            ...signup
          });
        });
        return signUpArray;
      })
    );
  }

  getUrl(url) {
    return `${this.apiUrl}/${url}`;
  }

  createSignupToken(data) {
    return this.http.post(this.getUrl('signup/create-signup-token'), data)
      .toPromise();
  }

  editSignupToken(data) {
    return this.http.post(this.getUrl('signup/edit-signup-token'), data)
      .toPromise();
  }

}
