import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireDatabaseModule} from '@angular/fire/database';

import {AppComponent} from './app.component';
import {environment} from '@env';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NbGlobalPhysicalPosition, NbThemeModule, NbToastrModule} from '@nebular/theme';
import {CoreModule} from '@app/core.module';
import {AuthModule} from '@modules/auth/auth.module';
import {AppRoutingModule} from './app-routing.module';
import {LayoutModule} from './layout/layout.module';
import {ErrorInterceptor} from '@app/interceptor/error.interceptor';
import {TokenInterceptor} from '@app/interceptor/token.interceptor';
import {ContentModule} from '@modules/content.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgxsModule} from '@ngxs/store';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';
import {LoadingComponent} from '@shared/components/loading/loading.component';
import {AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService} from '@angular/fire/analytics';
import {ServiceWorkerModule} from '@angular/service-worker';
import '@stripe/stripe-js';
import {SharedModule} from '@shared/shared.module';
import {SharedComponentsModule} from '@shared/components/shared-components.module';
import {AngularFireMessagingModule} from "@angular/fire/messaging";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireAnalyticsModule,
    AngularFireMessagingModule,
    NgxsModule.forRoot(
      [],
      {developmentMode: !environment.production},
    ),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsRouterPluginModule.forRoot(),
    AppRoutingModule,

    AuthModule,
    LayoutModule,
    CoreModule,
    ContentModule,
    SharedComponentsModule,

    NbThemeModule.forRoot({name: 'dark'}),
    NbToastrModule.forRoot({
      position: NbGlobalPhysicalPosition.TOP_RIGHT
    }),
    FontAwesomeModule,
    // ServiceWorkerModule.register('/ngsw-worker.js'),
    ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production})
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    ScreenTrackingService,
    UserTrackingService
    // {
    //   provide: FirestoreSettingsToken,
    //   useValue: environment.local ? {
    //     host: environment.firestoreUrl,
    //     ssl: false
    //   } : undefined
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
