import {Component, OnDestroy, OnInit} from '@angular/core';
import {NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';
import {filter, map, takeUntil} from 'rxjs/operators';
import {Observable, Subject, Subscription} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {Logout} from '@shared/state/auth/auth.actions';
import {AuthState} from '@shared/state/auth/auth.state';
import {CuratorUser} from '@shared/model';

@Component({
  selector: 'dlr-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  user: any;

  userMenu = [{title: 'Logout'}];
  userPictureOnly = false;
  mediaObservable: Subscription;

  @Select(AuthState.getUserData) user$: Observable<CuratorUser>;

  constructor(private sidebarService: NbSidebarService, private store: Store,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private breakpointService: NbMediaBreakpointsService) {
  }

  ngOnInit() {
    this.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe(userData => {
      this.user = userData;
    });
    const {xl} = this.breakpointService.getBreakpointsMap();
    this.mediaObservable = this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.menuService.onItemClick()
      .pipe(
        filter(({tag}) => tag === 'header-menu'),
        map(({item: {title}}) => title),
      )
      .subscribe(title => {
        if (title === 'Logout') {
          this.logout();
        }
      });
  }

  ngOnDestroy() {
    this.mediaObservable.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  logout() {
    this.store.dispatch(new Logout());
  }
}
