import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {SignupState} from '@shared/state/signup/signup.state';
import {Observable} from 'rxjs';
import {AddTokenComponent} from '@modules/signup/components/add-token/add-token.component';
import {DlrWindowService} from '../../../theme/components/window/window.service';

@Component({
  selector: 'dlr-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupComponent implements OnInit {
  @Select(SignupState.getSignUpTokensInfo) tokensInfo$: Observable<any[]>;

  constructor(private store: Store, private cd: ChangeDetectorRef, private windowService: DlrWindowService) {
  }

  ngOnInit(): void {
  }

  addSignUpToken() {
    this.windowService.open(AddTokenComponent, {
      title: 'Create Signup Token',
      closeOnBackdropClick: false
    });
  }
}
