import {NgModule} from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {SharedComponentsModule} from '@shared/components/shared-components.module';
import {PipeModule} from '@shared/pipes/pipe.module';
import {SignupComponent} from '@modules/signup/page/signup.component';
import { AddTokenComponent } from './components/add-token/add-token.component';
import {NbRadioModule} from '@nebular/theme';
import {TokenCardComponent} from '@modules/signup/components/token-card/token-card.component';
import {ClipboardModule} from 'ngx-clipboard';
import {DlrWindowModule} from '../../theme/components/window/window.module';

@NgModule({
  entryComponents: [AddTokenComponent],
  declarations: [SignupComponent, TokenCardComponent, AddTokenComponent],
  imports: [
    SharedModule,
    SharedComponentsModule,
    PipeModule,
    NbRadioModule,
    ClipboardModule,
    DlrWindowModule.forChild()
  ]
})
export class SignupModule {
}
