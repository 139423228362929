import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Org} from "@shared/model";
import {AddTokenComponent} from "@modules/signup/components/add-token/add-token.component";
import {ToastService} from "@app/service/util/toast.service";
import {DlrWindowService} from "../../../../theme/components/window/window.service";

@Component({
  selector: 'dlr-org-card',
  templateUrl: './org-card.component.html',
  styleUrls: ['./org-card.component.scss']
})
export class OrgCardComponent implements OnInit {
  @Input() orgInfo: Org;
  @Output() selectedOrgEmitter = new EventEmitter<Org>();
  constructor(private toastr: ToastService, private windowService: DlrWindowService) { }

  ngOnInit(): void {
  }

  copiedText(type = 'link') {
    this.toastr.success(`Successfully copied ${type} to clipboard`);
  }

  selectOrg() {
    this.selectedOrgEmitter.emit(this.orgInfo);
  }

  editTeamInfo() {
    this.windowService.open(AddTokenComponent, {
      title: 'Edit Team Info',
      closeOnBackdropClick: true,
      context: {
        orgInfo: this.orgInfo,
        event: 'edit'
      }
    });
  }
}
