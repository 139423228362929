<form [formGroup]="tokenForm" (ngSubmit)="onTokenSubmit()" class="form-container">
  <div class="form-group text-left">
    <label class="label" for="token-name">Company/Token Name</label>
    <input nbInput formControlName="name" id="token-name" placeholder="Token Name" autofocus fullWidth
           fieldSize="large"
           [status]="getTokenForm.name.dirty ? (getTokenForm.name.invalid  ? 'danger' : 'success') : 'basic'">
    <ng-container *ngIf="getTokenForm.name.invalid && getTokenForm.name.touched">
      <p class="caption status-danger" *ngIf="getTokenForm.name.errors?.required">
        Token name is required!
      </p>
    </ng-container>
  </div>
  <div class="form-group text-left">
    <label class="label" for="signup-email">User Signup Email</label>
    <input nbInput formControlName="email" id="signup-email" placeholder="Signup Email for User" autofocus fullWidth
           fieldSize="large"
           [status]="getTokenForm.email.dirty ? (getTokenForm.email.invalid  ? 'danger' : 'success') : 'basic'">
    <ng-container *ngIf="getTokenForm.email.invalid && getTokenForm.email.touched">
      <p class="caption status-danger" *ngIf="getTokenForm.email.errors?.required">
        Signup Email is required!
      </p>
    </ng-container>
  </div>
  <div class="form-group text-left">
    <label class="label" for="inventory-setup">Inventory Setup Pricing</label>

    <nb-radio-group formControlName="inventoryFeedsSetup" id="inventory-setup" [name]="'inventoryFeedsSetup'"
                    [status]="getTokenForm.inventoryFeedsSetup.dirty ? (getTokenForm.inventoryFeedsSetup.invalid ? 'danger' : 'success') : 'basic'">
      <nb-radio *ngFor="let product of (productsInfo$ | async | pricingPlan: 'inventory': 'one_time': 'Setup')"
                [value]="{product: product.id, price: product.price.id}">
        {{ product.name }} - {{product.price.unit_amount | money}}
        <span *ngIf="product.price.transform_quantity">Per {{product.price.transform_quantity.divide_by}} Units</span>
        - {{product.price.nickname}}
      </nb-radio>
    </nb-radio-group>
    <ng-container *ngIf="getTokenForm.inventoryFeedsSetup.invalid && getTokenForm.inventoryFeedsSetup.touched">
      <p class="caption status-danger" *ngIf="getTokenForm.inventoryFeedsSetup.errors?.required">
        Provide the Inventory Setup Plan that this token will be associated with
      </p>
    </ng-container>
  </div>
  <div class="form-group text-left">
    <label class="label" for="facebook-setup">Facebook Setup Pricing</label>

    <nb-radio-group formControlName="facebookAdsSetup" id="facebook-setup" [name]="'facebookSetup'"
                    [status]="getTokenForm.facebookAdsSetup.dirty ? (getTokenForm.facebookAdsSetup.invalid ? 'danger' : 'success') : 'basic'">
      <nb-radio *ngFor="let product of (productsInfo$ | async | pricingPlan: 'business': 'one_time': 'Setup')"
                [value]="{product: product.id, price: product.price.id}">
        {{ product.name }} - {{product.price.unit_amount | money}}
        <span *ngIf="product.price.transform_quantity">Per {{product.price.transform_quantity.divide_by}} Units</span>
        - {{product.price.nickname}}
      </nb-radio>
    </nb-radio-group>
    <ng-container *ngIf="getTokenForm.facebookAdsSetup.invalid && getTokenForm.facebookAdsSetup.touched">
      <p class="caption status-danger" *ngIf="getTokenForm.facebookAdsSetup.errors?.required">
        Provide the Facebook Setup Plan that this token will be associated with
      </p>
    </ng-container>
  </div>
  <div class="form-group text-left">
    <label class="label" for="vdpAnalysis-setup">VDP Analysis Setup Pricing</label>

    <nb-radio-group formControlName="vdpAnalysisSetup" id="vdpAnalysis-setup" [name]="'vdpAnalysisSetup'"
                    [status]="getTokenForm.vdpAnalysisSetup.dirty ? (getTokenForm.vdpAnalysisSetup.invalid ? 'danger' : 'success') : 'basic'">
      <nb-radio *ngFor="let product of (productsInfo$ | async | pricingPlan: 'vdp analysis': 'one_time': 'Setup')"
                [value]="{product: product.id, price: product.price.id}">
        {{ product.name }} - {{product.price.unit_amount | money}}
        <span *ngIf="product.price.transform_quantity">Per {{product.price.transform_quantity.divide_by}} Units</span>
        - {{product.price.nickname}}
      </nb-radio>
    </nb-radio-group>
    <ng-container *ngIf="getTokenForm.vdpAnalysisSetup.invalid && getTokenForm.vdpAnalysisSetup.touched">
      <p class="caption status-danger" *ngIf="getTokenForm.vdpAnalysisSetup.errors?.required">
        Provide the VDP Analysis Setup Plan that this token will be associated with
      </p>
    </ng-container>
  </div>
<!--  <div class="form-group text-left">-->
<!--    <label class="label" for="adwords-setup">Adwords Setup Pricing</label>-->

<!--    <nb-radio-group formControlName="adwordsSetup" id="adwords-setup" [name]="'adwordsSetup'"-->
<!--                    [status]="getTokenForm.adwordsSetup.dirty ? (getTokenForm.adwordsSetup.invalid ? 'danger' : 'success') : 'basic'">-->
<!--      <nb-radio *ngFor="let product of (productsInfo$ | async | pricingPlan: 'account': 'one_time')"-->
<!--                [value]="{product: product.id, price: product.price.id}">-->
<!--        {{ product.name }} - {{product.price.unit_amount | money}}-->
<!--        <span *ngIf="product.price.transform_quantity">Per {{product.price.transform_quantity.divide_by}} Units</span>-->
<!--        - {{product.price.nickname}}-->
<!--      </nb-radio>-->
<!--    </nb-radio-group>-->
<!--    <ng-container *ngIf="getTokenForm.adwordsSetup.invalid && getTokenForm.adwordsSetup.touched">-->
<!--      <p class="caption status-danger" *ngIf="getTokenForm.adwordsSetup.errors?.required">-->
<!--        Provide the Adworfs Setup Plan that this token will be associated with-->
<!--      </p>-->
<!--    </ng-container>-->
<!--  </div>-->
  <div class="form-group text-left">
    <label class="label" for="inventory">Inventory Recurring Pricing</label>

    <nb-radio-group formControlName="inventoryFeeds" id="inventory" [name]="'inventoryFeeds'"
                    [status]="getTokenForm.inventoryFeeds.dirty ? (getTokenForm.inventoryFeeds.invalid ? 'danger' : 'success') : 'basic'">
      <nb-radio *ngFor="let product of (productsInfo$ | async | pricingPlan: 'inventory': 'recurring': 'Recurring')"
                [value]="{product: product.id, price: product.price.id}">
        {{ product.name }} - {{product.price.unit_amount | money}}/month - {{product.price.nickname}}
      </nb-radio>
    </nb-radio-group>
    <ng-container *ngIf="getTokenForm.inventoryFeeds.invalid && getTokenForm.inventoryFeeds.touched">
      <p class="caption status-danger" *ngIf="getTokenForm.inventoryFeeds.errors?.required">
        Provide the Inventory Plan that this token will be associated with
      </p>
    </ng-container>
  </div>
  <div class="form-group text-left">
    <label class="label" for="facebook">Facebook Recurring Pricing</label>

    <nb-radio-group formControlName="facebookAds" id="facebook" [name]="'facebookAds'"
                    [status]="getTokenForm.facebookAds.dirty ? (getTokenForm.facebookAds.invalid ? 'danger' : 'success') : 'basic'">
      <nb-radio *ngFor="let product of (productsInfo$ | async | pricingPlan: 'business': 'recurring': 'Recurring')"
                [value]="{product: product.id, price: product.price.id}">
        {{ product.name }} - {{product.price.unit_amount | money}}/month - {{product.price.nickname}}
      </nb-radio>
    </nb-radio-group>
    <ng-container *ngIf="getTokenForm.facebookAds.invalid && getTokenForm.facebookAds.touched">
      <p class="caption status-danger" *ngIf="getTokenForm.facebookAds.errors?.required">
        Provide the Facebook Plan that this token will be associated with
      </p>
    </ng-container>
  </div>
  <div class="form-group text-left">
    <label class="label" for="vdp-analysis">VDP Analysis Recurring Pricing</label>

    <nb-radio-group formControlName="vdpAnalysis" id="vdp-analysis" [name]="'vdpAnalysis'"
                    [status]="getTokenForm.vdpAnalysis.dirty ? (getTokenForm.vdpAnalysis.invalid ? 'danger' : 'success') : 'basic'">
      <nb-radio *ngFor="let product of (productsInfo$ | async | pricingPlan: 'vdp analysis': 'recurring': 'Recurring')"
                [value]="{product: product.id, price: product.price.id}">
        {{ product.name }} - {{product.price.unit_amount | money}}/month - {{product.price.nickname}}
      </nb-radio>
    </nb-radio-group>
    <ng-container *ngIf="getTokenForm.vdpAnalysis.invalid && getTokenForm.vdpAnalysis.touched">
      <p class="caption status-danger" *ngIf="getTokenForm.vdpAnalysis.errors?.required">
        Provide the VDP Analysis Plan that this token will be associated with
      </p>
    </ng-container>
  </div>
<!--  <div class="form-group text-left">-->
<!--    <label class="label" for="adwords">Adwords Recurring Pricing</label>-->

<!--    <nb-radio-group formControlName="adwords" id="adwords" [name]="'adwords'"-->
<!--                    [status]="getTokenForm.adwords.dirty ? (getTokenForm.adwords.invalid ? 'danger' : 'success') : 'basic'">-->
<!--      <nb-radio *ngFor="let product of (productsInfo$ | async | pricingPlan: 'account': 'recurring')"-->
<!--                [value]="{product: product.id, price: product.price.id}">-->
<!--        {{ product.name }} - {{product.price.unit_amount | money}}/month-->
<!--      </nb-radio>-->
<!--    </nb-radio-group>-->
<!--    <ng-container *ngIf="getTokenForm.adwords.invalid && getTokenForm.adwords.touched">-->
<!--      <p class="caption status-danger" *ngIf="getTokenForm.adwords.errors?.required">-->
<!--        Provide the Adwords Plan that this token will be associated with-->
<!--      </p>-->
<!--    </ng-container>-->
<!--  </div>-->
  <div class="form-group text-left">
    <label class="label" for="inventory-one">Inventory One-time Pricing</label>
    <nb-radio-group formControlName="inventoryFeedsOneTime" id="inventory-one" [name]="'inventoryFeedsOneTime'"
                    [status]="getTokenForm.inventoryFeedsOneTime.dirty ? (getTokenForm.inventoryFeedsOneTime.invalid ? 'danger' : 'success') : 'basic'">
      <nb-radio *ngFor="let product of (productsInfo$ | async | pricingPlan: 'inventory': 'one_time': 'OneTime')"
                [value]="{product: product.id, price: product.price.id}">
        {{ product.name }} - {{product.price.unit_amount | money}} - {{product.price.nickname}}
      </nb-radio>
    </nb-radio-group>
    <ng-container *ngIf="getTokenForm.inventoryFeedsOneTime.invalid && getTokenForm.inventoryFeedsOneTime.touched">
      <p class="caption status-danger" *ngIf="getTokenForm.inventoryFeedsOneTime.errors?.required">
        Provide the Inventory Plan that this token will be associated with
      </p>
    </ng-container>
  </div>
  <div class="form-group text-left">
    <label class="label" for="facebook-ads-one">Facebook Ads One-time Pricing</label>

    <nb-radio-group formControlName="facebookAdsOneTime" id="facebook-ads-one" [name]="'facebookAdsOneTime'"
                    [status]="getTokenForm.facebookAdsOneTime.dirty ? (getTokenForm.facebookAdsOneTime.invalid ? 'danger' : 'success') : 'basic'">
      <nb-radio *ngFor="let product of (productsInfo$ | async | pricingPlan: 'business': 'one_time': 'OneTime')"
                [value]="{product: product.id, price: product.price.id}">
        {{ product.name }} - {{product.price.unit_amount | money}} - {{product.price.nickname}}
      </nb-radio>
    </nb-radio-group>
    <ng-container *ngIf="getTokenForm.facebookAdsOneTime.invalid && getTokenForm.facebookAdsOneTime.touched">
      <p class="caption status-danger" *ngIf="getTokenForm.facebookAdsOneTime.errors?.required">
        Provide the Facebook Plan that this token will be associated with
      </p>
    </ng-container>
  </div>
  <div class="form-group text-left">
    <label class="label" for="vdpAnalysis-one">VDP Analysis One-time Pricing</label>

    <nb-radio-group formControlName="vdpAnalysisOneTime" id="vdpAnalysis-one" [name]="'vdpAnalysisOneTime'"
                    [status]="getTokenForm.vdpAnalysisOneTime.dirty ? (getTokenForm.vdpAnalysisOneTime.invalid ? 'danger' : 'success') : 'basic'">
      <nb-radio *ngFor="let product of (productsInfo$ | async | pricingPlan: 'vdp analysis': 'one_time': 'OneTime')"
                [value]="{product: product.id, price: product.price.id}">
        {{ product.name }} - {{product.price.unit_amount | money}} - {{product.price.nickname}}
      </nb-radio>
    </nb-radio-group>
    <ng-container *ngIf="getTokenForm.vdpAnalysisOneTime.invalid && getTokenForm.vdpAnalysisOneTime.touched">
      <p class="caption status-danger" *ngIf="getTokenForm.vdpAnalysisOneTime.errors?.required">
        Provide the VDP Analysis Plan that this token will be associated with
      </p>
    </ng-container>
  </div>
<!--  <div class="form-group text-left">-->
<!--    <label class="label" for="adwords-one">Adwords One-time Pricing</label>-->

<!--    <nb-radio-group formControlName="adwordsOneTime" id="adwords-one" [name]="'adwordsOneTime'"-->
<!--                    [status]="getTokenForm.adwordsOneTime.dirty ? (getTokenForm.adwordsOneTime.invalid ? 'danger' : 'success') : 'basic'">-->
<!--      <nb-radio *ngFor="let product of (productsInfo$ | async | pricingPlan: 'account': 'one_time': 'OneTime')"-->
<!--                [value]="{product: product.id, price: product.price.id}">-->
<!--        {{ product.name }} - {{product.price.unit_amount | money}} - {{product.price.nickname}}-->
<!--      </nb-radio>-->
<!--    </nb-radio-group>-->
<!--    <ng-container *ngIf="getTokenForm.adwordsOneTime.invalid && getTokenForm.adwordsOneTime.touched">-->
<!--      <p class="caption status-danger" *ngIf="getTokenForm.adwordsOneTime.errors?.required">-->
<!--        Provide the Facebook Plan that this token will be associated with-->
<!--      </p>-->
<!--    </ng-container>-->
<!--  </div>-->

  <div class="form-control-group pb-5 pt-3">
    <button nbButton [status]="'success'" type="submit" class="create-button"
            [disabled]="loading || tokenForm.invalid"
            [nbSpinner]="loading" nbSpinnerStatus="success">
      {{submitText}}
    </button>
  </div>
</form>
