// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  local: false,
  production: false,
  firebase: {
    apiKey: 'AIzaSyAGQrvYIrCs0-L2bWTPrvFMM4D4QDc4Poc',
    authDomain: 'auth-dev.dealercurator.com',
    // authDomain: 'dealer-curator-dev.firebaseapp.com',
    databaseURL: 'https://dealer-curator-dev.firebaseio.com',
    projectId: 'dealer-curator-dev',
    storageBucket: 'dealer-curator-dev.appspot.com',
    messagingSenderId: '609819848724',
    appId: '1:609819848724:web:086ca3d7fbf48986843701',
    measurementId: 'G-FN4N6Z3LVN'
  },
  apiUrl: 'https://us-central1-dealer-curator-dev.cloudfunctions.net/api/v1/admin',
  firestoreUrl: 'localhost:8080',
  vapidKey: 'BB-Nc6zzl3KWTOVlz49ttqN7DwMNjrJyD2t2I_jMdVInAIrFHO_N5dMNIfb4SMmYwiBk5bA9sKS9AijVQzawgx4',
  // stripeKey: 'pk_test_j24hNEuz3iOOs7ShLUXlZS7q00McwK9Iqr',
  stripeKey: 'pk_test_51HHenWEPZabNe23ZcE6zZ2fGBldu3hU05oZiBfPin9XsC2f8AdW2zTsl4PPzbbtsRv9Ayow9dqeDEQQ5BBHCgmiv00mEkanmVq'
};
