import {NgModule} from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {ContentRoutingModule} from '@modules/content.routing';
import {DashboardModule} from '@modules/dashboard/dashboard.module';
import {NgxsModule} from '@ngxs/store';
import {AuthState} from '@shared/state/auth/auth.state';
import {SignupModule} from '@modules/signup/signup.module';
import {BillingState} from '@shared/state/billing/billing.state';
import {SignupState} from '@shared/state/signup/signup.state';
import {StoresModule} from "@modules/stores/stores.module";
import {OrgsState} from "@shared/state/orgs/orgs.state";

@NgModule({
  declarations: [],
  imports: [
    SharedModule,
    DashboardModule,
    SignupModule,
    StoresModule,
    ContentRoutingModule,
    NgxsModule.forFeature([ AuthState, BillingState, SignupState, OrgsState])
  ]
})
export class ContentModule { }
