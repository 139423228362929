import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private currentTheme: BehaviorSubject<string>;

  constructor() {
    this.currentTheme = new BehaviorSubject<string>(
      localStorage.getItem('currentTheme')
    );
  }

  setCurrentTheme(currentTheme: string) {
    this.currentTheme.next(currentTheme);
    localStorage.setItem('currentTheme', this.currentTheme.value.toString());
  }

  getCurrentTheme(): Observable<string> {
    return this.currentTheme;
  }
}
