import {Component, Input, OnInit} from '@angular/core';
import {StoreData} from "@shared/model";
import {ToastService} from "@app/service/util/toast.service";
import {DlrWindowService} from "../../../../theme/components/window/window.service";
import {EditStoreImageLogicComponent} from "@modules/stores/components/edit-store-image-logic/edit-store-image-logic.component";

@Component({
  selector: 'dlr-stores-card',
  templateUrl: './stores-card.component.html',
  styleUrls: ['./stores-card.component.scss']
})
export class StoresCardComponent implements OnInit {
  @Input() storeInfo: StoreData;
  @Input() orgID: string;
  constructor(private toastr: ToastService, private windowService: DlrWindowService) { }

  ngOnInit(): void {
  }

  editStoreInfo() {

  }

  editStoreImageLogic() {
    this.windowService.open(EditStoreImageLogicComponent, {
      title: 'Edit Store Info',
      closeOnBackdropClick: true,
      context: {
        storeInfo: this.storeInfo,
        orgID: this.orgID,
        event: 'edit'
      }
    });
  }

  editStoreVDPAnalysis() {

  }

  hasObjectValue(object = {}, value): boolean {
    return Object.values(object).includes(value) || false;
  }

}
