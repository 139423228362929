import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToastService} from '@app/service/util/toast.service';
import {AddTokenComponent} from '@modules/signup/components/add-token/add-token.component';
import {DlrWindowService} from '../../../../theme/components/window/window.service';

@Component({
  selector: 'dlr-token-card',
  templateUrl: './token-card.component.html',
  styleUrls: ['./token-card.component.scss']
})
export class TokenCardComponent implements OnInit {
  @Input() tokenInfo: any;

  constructor(private toastr: ToastService, private windowService: DlrWindowService) {
  }

  ngOnInit(): void {
  }

  deleteToken() {
    const isDelete = window.confirm('Are you sure you want to delete this token? It is not reversible!!!');
    if (isDelete) {
      // console.log(this.tokenInfo);
    }
  }

  copiedText(type = 'link') {
    this.toastr.success(`Successfully copied ${type} to clipboard`);
  }

  buildCopyContent(tokenID: string) {
    return `https://app.dealercurator.com/register?token=${tokenID}`;
  }

  editTokenInfo() {
    this.windowService.open(AddTokenComponent, {
      title: 'Edit Token Info',
      closeOnBackdropClick: true,
      context: {
        tokenInfo: this.tokenInfo,
        event: 'edit'
      }
    });
  }
}
