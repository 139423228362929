import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {Navigate} from '@ngxs/router-plugin';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(private router: Router, private ngZone: NgZone, private store: Store) {
  }

  navigateHome() {
    return this.store.dispatch(new Navigate(['/dashboard']));
  }

  navigateToStoreInventory() {
    return this.store.dispatch(new Navigate(['/inventory-feeds']));
  }

  navigateLogin() {
    return this.store.dispatch(new Navigate(['/login']));
  }

  navigateRegister() {
    return this.store.dispatch(new Navigate(['/register']));
  }

  navigateToPath(path) {
    return this.store.dispatch(new Navigate([path]));
  }

  // navigateHome() {
  //   return this.ngZone.run(() => {
  //     return this.router.navigate(['dashboard']);
  //   });
  // }
  //
  // navigateToStoreInventory() {
  //   return this.ngZone.run(() => {
  //     return this.router.navigate(['inventory-feeds']);
  //   });
  // }
  //
  // navigateLogin() {
  //   this.ngZone.run(() => {
  //     return this.router.navigate(['login']);
  //   });
  // }
  //
  // navigateRegister() {
  //   return this.ngZone.run(() => {
  //     return this.router.navigate(['register']);
  //   });
  // }
  //
  // navigateToPath(path, query?) {
  //   return this.ngZone.run(() => {
  //     if (query) {
  //       return this.router.navigate([path], {queryParams: query});
  //     } else {
  //       return this.router.navigate([path]);
  //     }
  //   });
  // }
}
