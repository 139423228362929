import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AnalyticsService} from './core/service/util/analytics.service';
import {NbIconLibraries} from '@nebular/theme';
import {LoggerService, LogLevel} from '@app/service/logger/logger.service';
import {environment} from '@env';
import {Actions, ofActionDispatched, Select, Store} from '@ngxs/store';
import {CheckSession, Logout} from '@shared/state/auth/auth.actions';
import {RoutingService} from '@app/service/routing.service';
import {AuthState} from '@shared/state/auth/auth.state';
import {Observable, Subject} from 'rxjs';
import {MessagingService} from '@shared/service/messaging.service';
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'dlr-root',
  templateUrl: './app.view.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  @Select(AuthState.getInitialized) initialized$: Observable<boolean>;
  message;

  constructor(private analytics: AnalyticsService, private iconLibraries: NbIconLibraries,
              private logService: LoggerService, private actions: Actions, private store: Store,
              private routingService: RoutingService, private msgService: MessagingService) {
    this.iconLibraries.registerFontPack('solid', {packClass: 'fas', iconClassPrefix: 'fa'});
    this.iconLibraries.registerFontPack('regular', {packClass: 'far', iconClassPrefix: 'fa'});
    this.iconLibraries.registerFontPack('light', {packClass: 'fal', iconClassPrefix: 'fa'});
    this.iconLibraries.registerFontPack('duotone', {packClass: 'fad', iconClassPrefix: 'fa'});
    this.iconLibraries.registerFontPack('brands', {packClass: 'fab', iconClassPrefix: 'fa'});

    this.iconLibraries.setDefaultPack('solid');
  }

  ngOnInit(): void {
    this.actions.pipe(ofActionDispatched(Logout))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.routingService.navigateLogin();
      });
    this.logService.level = environment.local ? LogLevel.All : LogLevel.Error;
    // this.msgService.getPermission();
    // this.msgService.receiveMessage();
    // this.message = this.msgService.currentMessage;
  }

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
