import {Component, OnInit} from '@angular/core';
import {Select, Store} from "@ngxs/store";
import {Observable} from "rxjs";
import {OrgsState} from "@shared/state/orgs/orgs.state";
import {Org} from "@shared/model";
import {GetStoresInOrg} from "@shared/state/orgs/orgs.actions";

@Component({
  selector: 'dlr-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss']
})
export class StoresComponent implements OnInit {
  @Select(OrgsState.getOrgInfo) orgsInfo$: Observable<Org[]>;
  @Select(OrgsState.getStoresInfo) storesInfo$: Observable<any>;
  currentOrg: string;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
  }

  showOrgDetails(orgInfo) {
    this.currentOrg = orgInfo.id;
    const storeInfo = this.store.selectSnapshot(OrgsState.getStoresInfo);
    if (!storeInfo[this.currentOrg]) {
      this.store.dispatch(new GetStoresInOrg({orgID: this.currentOrg}))
    }
  }

}
