import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '@app/service/auth.service';
import {RoutingService} from '@app/service/routing.service';
import {AuthState} from '@shared/state/auth/auth.state';
import {map} from 'rxjs/operators';
import {LoginRedirect} from '@shared/state/auth/auth.actions';
import {Store} from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class AuthStateGuard implements CanActivate {
  constructor(private authService: AuthService, private store: Store,
              private routeService: RoutingService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.selectOnce(AuthState.getUserToken).pipe(
      map(isAuth => {
        if (!isAuth) {
          return true;
        }
        return true;
      })
    );
  }
}
