import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {NbThemeModule} from '@nebular/theme';
import {DEFAULT_THEME} from './styles/theme.default';
import {DARK_THEME} from './styles/theme.dark';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [ DEFAULT_THEME, DARK_THEME ],
        ).providers,
      ],
    } as ModuleWithProviders;
  } }
