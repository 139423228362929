import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {AuthService} from '@app/service/auth.service';
import {environment} from '@env';
import {LoggerService} from '@app/service/logger/logger.service';
import {AuthState} from '@shared/state/auth/auth.state';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {LoginRedirect, Logout, RefreshToken} from '@shared/state/auth/auth.actions';
import {Store} from '@ngxs/store';
import {RoutingService} from '@app/service/routing.service';
import {interceptorConstants} from '@shared/model';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private logger: LoggerService, private store: Store,
              private routingService: RoutingService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url
    const token = this.store.selectSnapshot(AuthState.getUserToken);
    // const token = this.authService.userToken();
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (token && isApiUrl) {
      let authReq = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });
      // if (authReq.body) {
      //   const orgID = this.store.selectSnapshot(AuthState.getOrgID);
      //   authReq = authReq.clone({
      //     body: {...authReq.body, orgID}
      //   });
      // }

      // set request count limit on 500 error
      let errorCount = 0;

      // Pass on the cloned request instead of the original request.
      return next.handle(authReq).pipe(catchError((error, caught) => {

        if (error.status === interceptorConstants.UNAUTHORIZED) {
          // logout users, redirect to login page
          this.store.dispatch(new Logout());
        }

        if (error.status === interceptorConstants.TOKEN_EXPIRE) {
          return this.store.dispatch(new RefreshToken())
            .pipe(mergeMap((tokenResponse) => {
              this.logger.debug('TOKEN REFRESH RESPONSE', tokenResponse);
              authReq = request.clone({
                headers: request.headers.set('Authorization', `Bearer ${token}`)
              });
              return next.handle(authReq);
            }));
        }

        this.logger.error('error------>', error);

        if (error.status === interceptorConstants.INTERNAL_ERROR || error.status === interceptorConstants.GATEWAY_TIMEOUT) {
          if (errorCount < interceptorConstants.MAXIMUM_RE_REQUEST_LIMIT) {
            errorCount++;
            return next.handle(authReq);
          } else {
            this.routingService.navigateHome();
          }
        }

        // return all others errors
        return throwError(error);

      })) as any;
    } else {
      return next.handle(request);
    }
  }
}
