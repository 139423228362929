import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {LoggerService} from '@app/service/logger/logger.service';
import {GetAllPrices, GetInvoices,} from '@shared/state/billing/billing.actions';
import {BillingService} from '@shared/service/billing.service';
import {ToastService} from '@app/service/util/toast.service';

export interface BillingStateModel {
  productsInfo: any;
  invoicesInfo: any;
  loaded: boolean;
}

@State<BillingStateModel>({
  name: 'billing',
  defaults: {
    productsInfo: [],
    invoicesInfo: [],
    loaded: false,
  }
})
export class BillingState {
  duration = 10000;

  constructor(private logger: LoggerService, private billing: BillingService, private store: Store,
              private toast: ToastService) {

  }

  @Selector()
  static getProductsInfo(state: BillingStateModel) {
    return state.productsInfo;
  }

  @Selector()
  static getBillingLoaded(state: BillingStateModel) {
    return state.loaded;
  }

  @Action(GetAllPrices)
  getAllPrices({getState, patchState}: StateContext<BillingStateModel>) {
    return this.billing.getAllPrices()
      .then((res: any) => {
        const info = res.data;
        const prices: any[] = info.prices;
        const products: any[] = info.products;
        const result = [];

        if (!products) {
          return patchState({
            loaded: true
          });
        }

        for (const product of products) {
          product.prices = prices.filter(price => price.product === product.id);
          result.push(product);
        }
        patchState({
          productsInfo: result,
          loaded: true
        });
      })
      .catch(err => {
        patchState({
          loaded: true
        });
        this.toast.error(err.message, 'Please refresh the page!');
      });
  }

  @Action(GetInvoices)
  getInvoices({getState, patchState}: StateContext<BillingStateModel>) {
    return this.billing.getAllInvoices()
      .then((res: any) => {
        patchState({
          invoicesInfo: res,
          loaded: true
        });
      })
      .catch(err => {
        patchState({
          loaded: true
        });
        this.toast.error(err.message, 'Please refresh the page!');
      });
  }
}
