import {State, Action, Selector, StateContext, Store} from '@ngxs/store';
import {LoggerService} from '@app/service/logger/logger.service';
import {ToastService} from '@app/service/util/toast.service';
import {tap} from 'rxjs/operators';
import {EditStoreInvService, GetAllOrgs, GetStoreInvService, GetStoresInOrg} from "./orgs.actions";
import {OrgsService} from "@shared/service/orgs.service";

export interface OrgsStateModel {
  orgInfo: any[];
  storesInfo: {
    [k:string]: any[];
  };
  invServiceInfo: {
    [k:string]: any; // Per each store
  };
}

@State<OrgsStateModel>({
  name: 'orgs',
  defaults: {
    orgInfo: [],
    storesInfo: {},
    invServiceInfo: {}
  }
})
export class OrgsState {
  duration = 10000;

  constructor(private logger: LoggerService, private orgService: OrgsService, private store: Store,
              private toast: ToastService) {
  }

  @Selector()
  static getOrgInfo(state: OrgsStateModel) {
    return state.orgInfo;
  }

  @Selector()
  static getStoresInfo(state: OrgsStateModel) {
    return state.storesInfo;
  }

  @Selector()
  static getInvServiceInfo(state: OrgsStateModel) {
    return state.invServiceInfo;
  }

  @Action(GetAllOrgs)
  getAllTokens({getState, patchState}: StateContext<OrgsStateModel>) {
    return this.orgService.getAllOrgs()
      .pipe(tap(res => {
        patchState({
          orgInfo: res
        });
      }));
  }

  @Action(GetStoresInOrg)
  getStoresInOrg({getState, patchState}: StateContext<OrgsStateModel>, {payload}: GetStoresInOrg) {
    return this.orgService.getAllOrgsStores(payload.orgID)
      .pipe(tap(res => {
        const state = getState();
        let storesInfo = state.storesInfo;
        storesInfo = Object.assign({}, storesInfo, {[payload.orgID]: res});
        patchState({
          storesInfo
        });
      }));
  }

  @Action(GetStoreInvService)
  getStoreInvFeed({getState, patchState}: StateContext<OrgsStateModel>, {payload}: GetStoreInvService) {
    return this.orgService.getStoreInvServiceInfo(payload.orgID, payload.storeID)
      .pipe(tap(res => {
        const state = getState();
        let invServiceInfo = state.invServiceInfo;
        invServiceInfo = Object.assign({}, invServiceInfo, {[payload.storeID]: res});
        patchState({
          invServiceInfo
        });
      }));
  }

  @Action(EditStoreInvService)
  editStoreInvFeed({getState, patchState}: StateContext<OrgsStateModel>, {payload}: EditStoreInvService) {
    return this.orgService.editStoreInvFeed(payload)
      .then(() => {
        this.toast.success(`Updated the Inventory Feed Logic for store ${payload.storeID}`);
      })
      .catch((err) => {
        this.toast.error(err.message);
        this.logger.error('ERROR RES', err);
      });
  }
}
