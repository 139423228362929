import {State, Action, Selector, StateContext, Store, NgxsOnInit} from '@ngxs/store';
import {
  CheckSession,
  GetUser,
  Login,
  LoginFailure,
  LoginRedirect,
  LoginSuccess,
  Logout,
  LogoutSuccess, RefreshToken
} from '@shared/state/auth/auth.actions';
import {Org, ServerResponse, CuratorUser} from '@shared/model';
import {AngularFireAuth} from '@angular/fire/auth';
import {LoggerService} from '@app/service/logger/logger.service';
import {HttpClient} from '@angular/common/http';
import {AngularFireDatabase} from '@angular/fire/database';
import {RoutingService} from '@app/service/routing.service';
import {UserService} from '@shared/service/user.service';
import {ToastService} from '@app/service/util/toast.service';
import {AnalyticsService} from '@app/service/util/analytics.service';
import {tap} from 'rxjs/operators';
import * as firebase from 'firebase/app';
import 'firebase/auth';

export interface AuthStateModel {
  userData: Partial<CuratorUser>;
  tokenID: string;
  userID: string;
  initialized: boolean;
  error: any;
  redirectUrl: string;
  orgData: Partial<Org>;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    userData: {},
    tokenID: null,
    userID: null,
    initialized: false,
    error: {},
    redirectUrl: null,
    orgData: {}
  }
})
export class AuthState implements NgxsOnInit {
  constructor(private firebaseAuth: AngularFireAuth, private logger: LoggerService, private http: HttpClient,
              private firebaseDB: AngularFireDatabase, private routingService: RoutingService,
              private userService: UserService, private toastService: ToastService, private analytics: AnalyticsService) {
  }

  @Selector()
  static getUserData(state: AuthStateModel) {
    return state.userData;
  }

  @Selector()
  static getError(state: AuthStateModel) {
    return state.error;
  }

  @Selector()
  static getUserID(state: AuthStateModel) {
    return state.userID;
  }

  @Selector()
  static getUserToken(state: AuthStateModel) {
    return state.tokenID;
  }

  @Selector()
  static getOrgData(state: AuthStateModel) {
    return state.orgData;
  }

  @Selector()
  static getInitialized(state: AuthStateModel): boolean {
    return state.initialized;
  }

  /**
   * Dispatch CheckSession on start
   */
  ngxsOnInit(ctx: StateContext<AuthStateModel>) {
    ctx.dispatch(new CheckSession());
  }

  @Action(CheckSession)
  checkSession(sc: StateContext<AuthStateModel>) {
    return this.firebaseAuth.authState
      .subscribe(async (user) => {
        if (user) {
          sc.dispatch(new LoginSuccess(user));
        } else {
          sc.dispatch(new LoginFailure({error: 'No user logged in'}));
        }
      });
  }

  @Action(GetUser)
  getUserFromDB({getState, patchState, dispatch}: StateContext<AuthStateModel>) {
    const userID = getState().userID;
    return this.userService.getUserData(userID).pipe(tap((result) => {
      if (!result) {
        return;
      }
      const userData = result;
      patchState({
        userData,
        initialized: true
      });
      return userData;
    }));
  }

  /**
   * Redirect the user to the page they requested after the User data is added
   * @param sc
   */
  @Action(GetUser)
  onLoginSuccess(sc: StateContext<AuthStateModel>) {
    const redirectUrl = sc.getState().redirectUrl;
    if (redirectUrl) {
      sc.patchState({
        redirectUrl: null
      });
      return this.routingService.navigateToPath(redirectUrl);
    }
    return this.routingService.navigateHome();
  }

  @Action(Login)
  signInWithEmail(sc: StateContext<AuthStateModel>) {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({hd: 'dealercurator.com'});
    return this.firebaseAuth.auth.signInWithPopup(provider)
      .then((user: firebase.auth.UserCredential) => {
        return sc.dispatch(new LoginSuccess(user.user));
      })
      .catch(error => {
        return sc.dispatch(new LoginFailure(error));
      });
  }

  @Action(Logout)
  logout(sc: StateContext<AuthStateModel>) {
    this.firebaseAuth.auth.signOut().then(
      () => {
        return sc.dispatch(new LogoutSuccess());
      });
  }

  @Action(LoginSuccess)
  async setUserStateOnSuccess(sc: StateContext<AuthStateModel>, {payload}: LoginSuccess) {
    sc.patchState({
      tokenID: await payload.getIdToken(true),
      userID: payload.uid
    });
    sc.dispatch(new GetUser());
  }

  @Action(LoginRedirect)
  redirectLogin(sc: StateContext<AuthStateModel>, {redirectUrl}: LoginRedirect) {
    if (redirectUrl) {
      sc.patchState({
        redirectUrl
      });
    }
    return this.routingService.navigateLogin();
  }

  @Action([LoginFailure, LogoutSuccess])
  setUserStateOnFailure(sc: StateContext<AuthStateModel>, {payload}: LoginFailure) {
    if (payload && payload.message) {
      this.toastService.error(payload.message);
      sc.patchState({
        error: {
          message: payload.message,
          code: payload.code
        }
      });
    }
    sc.patchState({
      tokenID: null,
      userData: {},
      userID: null,
      initialized: true
    });
  }

  @Action(RefreshToken)
  refreshToken(sc: StateContext<AuthStateModel>) {
    return this.firebaseAuth.auth.currentUser.getIdToken(true)
      .then((tokenID) => {
        sc.patchState({
          tokenID
        });
        return tokenID;
      }).catch((error) => {
        this.logger.error(error);
        // TODO: Properly handle errors
      });
  }
}
