import { ModuleWithProviders, NgModule } from '@angular/core';
import { DlrWindowService } from './window.service';
import { DlrWindowsContainerComponent } from './windows-container.component';
import { DlrWindowComponent } from './window.component';
import { NB_WINDOW_CONFIG, DlrWindowConfig } from './window.options';
import {SharedModule} from '@shared/shared.module';
import {NbOverlayModule} from '@nebular/theme';

@NgModule({
  imports: [ SharedModule, NbOverlayModule ],
  declarations: [
    DlrWindowsContainerComponent,
    DlrWindowComponent,
  ],
  entryComponents: [DlrWindowsContainerComponent, DlrWindowComponent],
})
export class DlrWindowModule {
  static forRoot(defaultConfig?: Partial<DlrWindowConfig>): ModuleWithProviders<DlrWindowModule> {
    return {
      ngModule: DlrWindowModule,
      providers: [
        DlrWindowService,
        { provide: NB_WINDOW_CONFIG, useValue: defaultConfig },
      ],
    };
  }

  static forChild(defaultConfig?: Partial<DlrWindowConfig>): ModuleWithProviders<DlrWindowModule> {
    return {
      ngModule: DlrWindowModule,
      providers: [
        DlrWindowService,
        { provide: NB_WINDOW_CONFIG, useValue: defaultConfig },
      ],
    };
  }
}
