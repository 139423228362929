import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {MENU_ITEMS, THEMES} from './content-const';
import {map, take, takeUntil} from 'rxjs/operators';
import {NbThemeService} from '@nebular/theme';
import {Observable, Subject, Subscription} from 'rxjs';
import {ThemeService} from '@app/service/util/theme.service';
import {Select, Store} from '@ngxs/store';
import {StoreData, CuratorUser} from '@shared/model';
import {AuthState} from '@shared/state/auth/auth.state';
import {GetAllPrices} from '@shared/state/billing/billing.actions';
import {GetAllTokens} from '@shared/state/signup/signup.actions';
import {GetAllOrgs} from "@shared/state/orgs/orgs.actions";

@Component({
  selector: 'dlr-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss']
})
export class ContentLayoutComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  @Select(AuthState.getUserData) userData$: Observable<Partial<CuratorUser>>;

  menu = MENU_ITEMS;
  themes = THEMES;
  currentTheme = 'default';
  themeObservable: Subscription;

  constructor(
    private themeService: ThemeService,
    private nbThemeService: NbThemeService,
    private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(new GetAllPrices());
    this.store.dispatch(new GetAllOrgs());
    this.store.dispatch(new GetAllTokens());
    this.themeObservable = this.themeService.getCurrentTheme().pipe(take(1))
      .subscribe(currentTheme => {
        if (currentTheme) {
          this.currentTheme = currentTheme;
          this.nbThemeService.changeTheme(currentTheme);
        } else {
          this.currentTheme = 'dark';
        }
      });
  }

  changeTheme(themeName: string) {
    this.nbThemeService.changeTheme(themeName);
    this.themeService.setCurrentTheme(themeName);
  }

  ngOnDestroy() {
    this.themeObservable.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
