import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {Observable} from 'rxjs';
import * as firebase from 'firebase/app';
import {CuratorUser} from '@shared/model';
import {AngularFireDatabase} from '@angular/fire/database';
import {LoggerService} from '@app/service/logger/logger.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@env';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  headers: HttpHeaders;
  apiUrl: string;
  private user: Observable<firebase.User>;
  userData: firebase.User = null;

  constructor(private firebaseAuth: AngularFireAuth, private logger: LoggerService, private http: HttpClient,
              private firebaseDB: AngularFireDatabase) {
    this.user = this.firebaseAuth.authState;
    this.apiUrl = environment.apiUrl;
  }
}
