import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Select, Store} from "@ngxs/store";
import {RoutingService} from "@app/service/routing.service";
import {ToastService} from "@app/service/util/toast.service";
import {DlrWindowRef} from "../../../../theme/components/window/window-ref";
import {EditStoreInvService, GetStoreInvService} from "@shared/state/orgs/orgs.actions";
import {takeUntil} from "rxjs/operators";
import {Observable, Subject} from "rxjs";
import {OrgsState} from "@shared/state/orgs/orgs.state";

@Component({
  selector: 'dlr-edit-store-image-logic',
  templateUrl: './edit-store-image-logic.component.html',
  styleUrls: ['./edit-store-image-logic.component.scss']
})
export class EditStoreImageLogicComponent implements OnInit, OnDestroy {
  @Select(OrgsState.getInvServiceInfo) serviceInfo$: Observable<any>;
  invErrorMessages: string[];
  invForm: FormGroup;
  loading: boolean;
  submitText = 'CREATE SIGNUP TOKEN';
  storeID: string;
  orgID: string;
  storeInfo: any = {};
  invInfo: any;
  private ngUnSubscribe: Subject<void> = new Subject<void>();

  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef, private router: RoutingService,
              private toastr: ToastService, private store: Store, private windowRef: DlrWindowRef) {
  }


  ngOnInit(): void {
    this.invForm = this.fb.group({
      stockDomainInput: ['', Validators.required],
      stockMinNoOfVehicles: ['', [Validators.required]],
      stockUrlPhrases: this.fb.array([]),
      noImgDomainInput: [''],
      noCheckImages: [2],
      noImgUrlPhrases: this.fb.array([])
    });
    this.invErrorMessages = [];
    this.loading = false;

    // This is an edit request
    if (!this.windowRef) {
      return;
    }

    const windowContext: any = this.windowRef.config.context;
    if (windowContext && windowContext.event === 'edit') {
      this.submitText = 'EDIT INV IMAGE LOGIC';
      this.storeInfo = windowContext.storeInfo;
      this.orgID = windowContext.orgID;
      this.storeID = this.storeInfo.id;
      if (!this.storeInfo || !this.storeID) {
        this.toastr.warning('No store data found token!');
        return;
      }

      this.store.dispatch(new GetStoreInvService({
        orgID: this.orgID,
        storeID: this.storeID,
      }));
      this.serviceInfo$
        .pipe(takeUntil(this.ngUnSubscribe))
        .subscribe(invObj => {
          if (invObj[this.storeID]) {
            this.invInfo = invObj[this.storeID];
            this.invForm = this.fb.group({
              stockDomainInput: [this.invInfo.stockDomainInput, Validators.required],
              noCheckImages: [this.invInfo.noCheckImages, Validators.required],
              stockMinNoOfVehicles: [this.invInfo.stockMinNoOfVehicles, [Validators.required]],
              stockUrlPhrases: this.fb.array([]),
              noImgDomainInput: [this.invInfo.noImgDomainInput],
              noImgUrlPhrases: this.fb.array([]),
            });
            if (this.invInfo.stockUrlPhrases) {
              for (const data of this.invInfo.stockUrlPhrases) {
                this.addPhrase(data);
              }
            }
            if (this.invInfo.noImgUrlPhrases) {
              for (const data of this.invInfo.noImgUrlPhrases) {
                this.addNoImgPhrase(data);
              }
            }
          }
        })
    }
  }

  get getInvForm() {
    return this.invForm.controls;
  }

  get stockUrlPhrasesData() {
    return this.invForm.get('stockUrlPhrases') as FormArray;
  }

  get noImgUrlPhrasesData() {
    return this.invForm.get('noImgUrlPhrases') as FormArray;
  }

  addPhrase(phraseData = '') {
    return this.stockUrlPhrasesData.push(new FormControl(phraseData));
  }

  removePhrase(phraseIndex) {
    this.stockUrlPhrasesData.removeAt(phraseIndex);
  }

  addNoImgPhrase(phraseData = '') {
    return this.noImgUrlPhrasesData.push(new FormControl(phraseData));
  }

  removeNoImgPhrase(phraseIndex) {
    this.noImgUrlPhrasesData.removeAt(phraseIndex);
  }

  ngOnDestroy() {
    this.ngUnSubscribe.next();
    this.ngUnSubscribe.complete();
  }

  onInvServiceSubmit() {
    if (this.invForm.invalid) {
      this.toastr.warning(`The inventory service form is invalid`);
      return;
    }
    this.loading = true;

    const invObject = {
      ...this.invForm.value,
      storeID: this.storeID,
      orgID: this.orgID
    };

    this.windowRef.close();
    return this.store.dispatch(new EditStoreInvService(invObject));
  }
}
