import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFirestore} from '@angular/fire/firestore';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@env';
import {map, take} from 'rxjs/operators';
import {Store} from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class OrgsService {
  headers: HttpHeaders;
  apiUrl: string;

  constructor(private databaseService: AngularFireDatabase, private firestore: AngularFirestore,
              private http: HttpClient, private store: Store) {
    this.apiUrl = environment.apiUrl;
  }

  getAllOrgs() {
    const path = `orgs`;
    return this.firestore.collection<any>(path).snapshotChanges().pipe(
      // take(1), // If the reads start getting expensive
      map(documents => {
        const orgArray: any[] = [];
        documents.forEach(doc => {
          const org = doc.payload.doc.data();
          const orgID = doc.payload.doc.id;
          orgArray.push({
            id: orgID,
            ...org
          });
        });
        return orgArray;
      })
    );
  }

  getAllOrgsStores(orgId: string) {
    const path = `orgs`;
    return this.firestore.collection<any>(path).doc(orgId).collection('stores').snapshotChanges().pipe(
      // take(1), // If the reads start getting expensive
      map(documents => {
        const storeArray: any[] = [];
        documents.forEach(doc => {
          const store = doc.payload.doc.data();
          const storeID = doc.payload.doc.id;
          storeArray.push({
            id: storeID,
            ...store
          });
        });
        return storeArray;
      })
    );
  }

  getUrl(url) {
    return `${this.apiUrl}/${url}`;
  }

  getStoreInvServiceInfo(orgId: string, storeId: string) {
    return this.firestore.collection(`orgs/${orgId}/stores/${storeId}/services`)
      .doc('inventoryFeeds').snapshotChanges().pipe(
        take(1), // If the reads start getting expensive
        map(document => {
          const data: any = document.payload.data();
          return {
            ...data,
            id: document.payload.id
          };
        })
      );
  }

  editStoreInvFeed(data) {
    return this.firestore.collection(`orgs/${data.orgID}/stores/${data.storeID}/services`)
      .doc('inventoryFeeds')
      .update(data);
  }

}
