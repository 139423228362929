
export enum ActionTypes {
  GET_ALL_ORGS = '[Orgs] GetOrgs',
  GET_STORES_IN_ORG = '[Orgs] GetOrgsStores',
  GET_STORE_INV_SERVICE = '[Orgs] GetStoreInvService',
  EDIT_STORE_INV_SERVICE = '[Orgs] EditStoreInvService',
}

export class GetAllOrgs {
  static readonly type = ActionTypes.GET_ALL_ORGS;
  constructor() {}
}

export class GetStoresInOrg {
  static readonly type = ActionTypes.GET_STORES_IN_ORG;
  constructor(public payload: { orgID: string }) {}
}

export class GetStoreInvService {
  static readonly type = ActionTypes.GET_STORE_INV_SERVICE;
  constructor(public payload: { orgID: string, storeID: string }) {}
}

export class EditStoreInvService {
  static readonly type = ActionTypes.EDIT_STORE_INV_SERVICE;
  constructor(public payload: any) {}
}
