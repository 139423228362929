import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'pricingPlan'
})
export class PricingPlanPipe implements PipeTransform {

  transform(productArr: any[], unit: string = 'setup', type: string = 'one_time', nickname: string): any[] {
    const result = [];
    for (const product of productArr) {
      const prices = product.prices;

      for (const price of prices) {
        let priceNickname = price.nickname;
        if (product.unit_label && product.unit_label.toLowerCase().includes(unit) && price.type === type
          && priceNickname.indexOf(nickname) != -1) {
          result.push({
            ...product,
            price
          });
        }
      }
    }
    return result;
  }

}
