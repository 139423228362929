<div class="row">
  <div class="col-md-6">
    <nb-card class="store-card">
      <nb-card-header>
        <h6 class="store-title">All Teams in Dealer Curator</h6>
      </nb-card-header>
      <nb-card-body class="store-card-body">
        <dlr-org-card *ngFor="let org of (orgsInfo$ | async)" [orgInfo]="org" (selectedOrgEmitter)="showOrgDetails($event)"></dlr-org-card>
      </nb-card-body>
    </nb-card>
  </div>
  <div class="col-md-6">
    <nb-card class="store-card">
      <nb-card-header>
        <h6 class="store-title">Teams Stores</h6>
      </nb-card-header>
      <nb-card-body class="store-card-body" *ngIf="currentOrg">
        <dlr-stores-card *ngFor="let store of (storesInfo$ | async)[currentOrg]" [storeInfo]="store"
                         [orgID]="currentOrg"></dlr-stores-card>
      </nb-card-body>
    </nb-card>
  </div>
</div>
