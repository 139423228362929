<div class="row">
  <div class="col-md-12">
    <nb-card class="signup-card">
      <nb-card-header>
        <h6 class="sign-up-title">All Signup Tokens</h6>
        <button nbButton status="primary" size="medium" class="signup-add-btn" (click)="addSignUpToken()">
          <nb-icon icon="user-plus" class="mr-2"></nb-icon>
          Create SignUp Token
        </button>
      </nb-card-header>
      <nb-card-body class="signup-card-body">
        <dlr-token-card *ngFor="let token of (tokensInfo$ | async)" [tokenInfo]="token" class="col-md-12"></dlr-token-card>
      </nb-card-body>
    </nb-card>
  </div>
</div>
