import {AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RoutingService} from '@app/service/routing.service';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {StoreData} from '@shared/model';
import {BillingState} from '@shared/state/billing/billing.state';
import {ToastService} from '@app/service/util/toast.service';
import {AddSignupToken, EditSignupToken} from '@shared/state/signup/signup.actions';
import {DlrWindowRef} from '../../../../theme/components/window/window-ref';

@Component({
  selector: 'dlr-add-token',
  templateUrl: './add-token.component.html',
  styleUrls: ['./add-token.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddTokenComponent implements OnInit, AfterViewChecked {
  tokenErrorMessages: string[];
  tokenForm: FormGroup;
  @Select(BillingState.getProductsInfo) productsInfo$: Observable<any[]>;
  loading: boolean;
  submitText = 'CREATE SIGNUP TOKEN';
  tokenID: string;
  tokenInfo: any = {};

  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef, private router: RoutingService,
              private toastr: ToastService, private store: Store, private windowRef: DlrWindowRef) {
  }

  ngOnInit(): void {
    this.tokenForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      inventoryFeedsSetup: ['', [Validators.required]],
      facebookAdsSetup: ['', [Validators.required]],
      vdpAnalysisSetup: ['', [Validators.required]],
      // adwordsSetup: ['', [Validators.required]],
      inventoryFeeds: ['', [Validators.required]],
      facebookAds: ['', [Validators.required]],
      vdpAnalysis: ['', [Validators.required]],
      // adwords: ['', [Validators.required]],
      inventoryFeedsOneTime: ['', [Validators.required]],
      facebookAdsOneTime: ['', [Validators.required]],
      vdpAnalysisOneTime: ['', [Validators.required]],
      // adwordsOneTime: ['', [Validators.required]],
    });
    this.tokenErrorMessages = [];
    this.loading = false;

    // This is an edit request
    if (!this.windowRef) {
      return;
    }
    const windowContext: any = this.windowRef.config.context;
    if (windowContext && windowContext.event === 'edit') {
      this.submitText = 'EDIT SIGNUP TOKEN';
      this.tokenInfo = windowContext.tokenInfo;
      this.tokenID = this.tokenInfo.id;
      if (!this.tokenInfo || !this.tokenID) {
        this.toastr.warning('You cannot edit an empty Signup token!');
        return;
      }
      this.tokenForm = this.fb.group({
        name: [this.tokenInfo.name, Validators.required],
        email: [this.tokenInfo.email, [Validators.required, Validators.email]],
        inventoryFeedsSetup: [this.tokenInfo.inventoryFeedsSetup, [Validators.required]],
        facebookAdsSetup: [this.tokenInfo.facebookAdsSetup, [Validators.required]],
        vdpAnalysisSetup: [this.tokenInfo.vdpAnalysisSetup, [Validators.required]],
        // adwordsSetup: [this.tokenInfo.adwordsSetup, [Validators.required]],
        inventoryFeeds: [this.tokenInfo.inventoryFeeds, [Validators.required]],
        facebookAds: [this.tokenInfo.facebookAds, [Validators.required]],
        vdpAnalysis: [this.tokenInfo.vdpAnalysis, [Validators.required]],
        // adwords: [this.tokenInfo.adwords, [Validators.required]],
        inventoryFeedsOneTime: [this.tokenInfo.inventoryFeedsOneTime, [Validators.required]],
        facebookAdsOneTime: [this.tokenInfo.facebookAdsOneTime, [Validators.required]],
        vdpAnalysisOneTime: [this.tokenInfo.vdpAnalysisOneTime, [Validators.required]],
        // adwordsOneTime: [this.tokenInfo.adwordsOneTime, [Validators.required]],
      });
    }
  }

  // After a view changes, use the change detector to ensure the change propagates everywhere
  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  get getTokenForm() {
    return this.tokenForm.controls;
  }

  onTokenSubmit() {
    if (this.tokenForm.invalid) {
      this.toastr.warning(`The user profile you are trying to create is incomplete`);
      return;
    }
    this.loading = true;

    const tokenObject = {
      ...this.tokenInfo,
      ...this.tokenForm.value
    };

    this.windowRef.close();
    if (this.tokenID) {
      tokenObject.tokenID = this.tokenID;
      return this.store.dispatch(new EditSignupToken(tokenObject));
    }
    return this.store.dispatch(new AddSignupToken(tokenObject));
  }
}
