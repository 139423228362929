<nb-card class="token-card">
  <nb-card-body class="token-card-body">
    <div class="row body-row">
      <div class="col-sm-12">
        <p class="font-weight-bold mb-1">{{storeInfo.name}} • {{storeInfo.id}} • {{storeInfo.website}}</p>
        <p class="mb-1">
          Created: {{storeInfo.createdAt | momentCalendar}}
          <span *ngIf="storeInfo.updatedAt">&emsp;Updated: {{storeInfo.updatedAt | momentCalendar}}</span>
        </p>
        <div class="row body-row">
          <div class="col-sm-6">
            <p class="mb-0"><strong>Alarms:</strong> {{storeInfo.counts.alarms}}</p>
            <p class="mb-0"><strong>Reports:</strong> {{storeInfo.counts.reports}}</p>
          </div>
          <div class="col-sm-6">
            <p class="mb-0"><strong>Feeds:</strong> {{storeInfo.counts.feeds}}</p>
            <p class="mb-0"><strong>Inventory:</strong> {{storeInfo.counts.inventory}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="right-container">
      <nb-actions size="giant" class="store-info-icon">
        <nb-action icon="edit" (click)="editStoreInfo()" title="Edit Store Info"></nb-action>
        <nb-action nbTooltipPlacement="right" nbTooltipTrigger="hover" (click)="editStoreImageLogic()"
                   icon="rss-square" *ngIf="storeInfo.services && hasObjectValue(storeInfo.services, 'inventoryFeeds')">
        </nb-action>
        <nb-action nbTooltipPlacement="right" nbTooltipTrigger="hover" (click)="editStoreVDPAnalysis()"
                   icon="chart-line" *ngIf="storeInfo.services && hasObjectValue(storeInfo.services, 'vdpAnalysis')">
        </nb-action>
        <nb-action nbTooltipPlacement="right" nbTooltipTrigger="hover"
                   *ngIf="storeInfo.services && hasObjectValue(storeInfo.services, 'facebookAds')">
          <nb-icon icon="facebook-square" pack="brands"></nb-icon>
        </nb-action>
      </nb-actions>
    </div>
  </nb-card-body>
</nb-card>
