<nb-layout windowMode>
  <nb-layout-header fixed>
    <dlr-header></dlr-header>
  </nb-layout-header>

  <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive start>
    <nb-menu [items]="menu"></nb-menu>
    <nb-sidebar-footer >
      <label class="label">Select Theme</label><br>
      <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
        <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
      </nb-select>
    </nb-sidebar-footer>
  </nb-sidebar>

  <nb-layout-column>
    <router-outlet></router-outlet>
  </nb-layout-column>

  <nb-layout-footer fixed>
    <dlr-footer></dlr-footer>
  </nb-layout-footer>
</nb-layout>
