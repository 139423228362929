<form [formGroup]="invForm" (ngSubmit)="onInvServiceSubmit()" class="form-container">
  <div class="form-group text-left">
    <label class="label" for="stock-domain-name">Number of Vehicles to Check</label>
    <input nbInput formControlName="noCheckImages" id="stock-domain-name" placeholder="No of Vehicles" autofocus fullWidth
           fieldSize="medium"
           [status]="getInvForm.noCheckImages.dirty ? (getInvForm.noCheckImages.invalid  ? 'danger' : 'success') : 'basic'">
    <ng-container *ngIf="getInvForm.noCheckImages.invalid && getInvForm.noCheckImages.touched">
      <p class="caption status-danger" *ngIf="getInvForm.noCheckImages.errors?.required">
        No of vehicles to check is required!
      </p>
    </ng-container>
  </div>
  <div class="form-group text-left">
    <label class="label" for="token-name">Stock Domain Input</label>
    <input nbInput formControlName="stockDomainInput" id="token-name" placeholder="Stock Domain Name" autofocus fullWidth
           fieldSize="medium"
           [status]="getInvForm.stockDomainInput.dirty ? (getInvForm.stockDomainInput.invalid  ? 'danger' : 'success') : 'basic'">
    <ng-container *ngIf="getInvForm.stockDomainInput.invalid && getInvForm.stockDomainInput.touched">
      <p class="caption status-danger" *ngIf="getInvForm.stockDomainInput.errors?.required">
        Stock Domain Input is required!
      </p>
    </ng-container>
  </div>
  <div class="form-group text-left">
    <label class="label" for="signup-email">If vehicle has less than this number it's stock</label>
    <input nbInput formControlName="stockMinNoOfVehicles" id="signup-email" placeholder="Min number of vehicles" autofocus fullWidth
           fieldSize="medium" type="number"
           [status]="getInvForm.stockMinNoOfVehicles.dirty ? (getInvForm.stockMinNoOfVehicles.invalid  ? 'danger' : 'success') : 'basic'">
    <ng-container *ngIf="getInvForm.stockMinNoOfVehicles.invalid && getInvForm.stockMinNoOfVehicles.touched">
      <p class="caption status-danger" *ngIf="getInvForm.stockMinNoOfVehicles.errors?.required">
        Minimum number of vehicles is required!
      </p>
    </ng-container>
  </div>

  <div>
    <h5 class="label">Stock URL Phrases, must have at least 1, even http works</h5>
    <nz-empty *ngIf="stockUrlPhrasesData.length == 0" nzNotFoundImage="./assets/images/empty.svg"
              [nzNotFoundContent]="contentTpl" [nzNotFoundFooter]="footerTpl" class="text-center">
      <ng-template #contentTpl>
        <span>NO DATA</span>
      </ng-template>
      <ng-template #footerTpl>
        <button nbButton status="primary" size="small" (click)="addPhrase()" type="button">
          <nb-icon icon="filter" class="mr-2"></nb-icon>
          Add Filters
        </button>
      </ng-template>
    </nz-empty>
    <div class="form-group text-left" formArrayName="stockUrlPhrases"
         *ngFor="let phrase of stockUrlPhrasesData.controls; let i = index;">
      <div class="mt-2 text-center" *ngIf="i > 0">
        <h6 class="font-weight-bold">OR</h6>
      </div>
      <nb-card class="mt-3 filter-group-card mb-1">
        <nb-card-body class="filter-group-body p-2">
          <label class="label" for="phrase">Phrase {{i + 1}}</label>
          <div class="">
            <div class="form-group text-left">
              <input nbInput [formControlName]="i" id="phrase" fullWidth fieldSize="small" placeholder="Phrase">
            </div>
          </div>
          <div class="text-center">
            <button nbButton status="success" [size]="'small'" (click)="addPhrase('')" type="button" class="mr-2"
                    *ngIf="i + 1 == stockUrlPhrasesData.length && i + 1 < 4">
              <nb-icon icon="plus"></nb-icon>
            </button>
            <button nbButton status="danger" [size]="'small'" (click)="removePhrase(i)" type="button">
              <nb-icon icon="minus"></nb-icon>
            </button>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
  <div class="form-group text-left">
    <label class="label" for="domain-input">No Image Domain</label>
    <input nbInput formControlName="noImgDomainInput" id="domain-input" placeholder="No Image Domain" autofocus fullWidth
           fieldSize="medium"
           [status]="getInvForm.noImgDomainInput.dirty ? (getInvForm.noImgDomainInput.invalid  ? 'danger' : 'success') : 'basic'">
    <ng-container *ngIf="getInvForm.noImgDomainInput.invalid && getInvForm.noImgDomainInput.touched">
      <p class="caption status-danger" *ngIf="getInvForm.noImgDomainInput.errors?.required">
        No Image Domain is required!
      </p>
    </ng-container>
  </div>

  <div class="">
    <h5 class="label">Image Not Found URL Phrases, must have at least 1, even http works</h5>
    <nz-empty *ngIf="noImgUrlPhrasesData.length == 0" nzNotFoundImage="./assets/images/empty.svg"
              [nzNotFoundContent]="contentTpl" [nzNotFoundFooter]="footerTpl" class="text-center">
      <ng-template #contentTpl>
        <span>NO DATA</span>
      </ng-template>
      <ng-template #footerTpl>
        <button nbButton status="primary" size="small" (click)="addNoImgPhrase()" type="button">
          <nb-icon icon="filter" class="mr-2"></nb-icon>
          Add Filters
        </button>
      </ng-template>
    </nz-empty>
    <div class="form-group text-left" formArrayName="noImgUrlPhrases"
         *ngFor="let phrase of noImgUrlPhrasesData.controls; let i = index;">
      <div class="mt-2 text-center" *ngIf="i > 0">
        <h6 class="font-weight-bold">OR</h6>
      </div>
      <nb-card class="mt-3 filter-group-card mb-1">
        <nb-card-body class="filter-group-body p-3">
          <label class="label" for="no-img-phrase">Phrase {{i + 1}}</label>
          <div class="">
            <div class="form-group text-left">
              <input nbInput [formControlName]="i" id="no-img-phrase" fullWidth fieldSize="small" placeholder="Phrase">
            </div>
          </div>
          <div class="text-center">
            <button nbButton status="success" [size]="'small'" (click)="addNoImgPhrase('')" type="button" class="mr-2"
                    *ngIf="i + 1 == noImgUrlPhrasesData.length && i + 1 < 4">
              <nb-icon icon="plus"></nb-icon>
            </button>
            <button nbButton status="danger" [size]="'small'" (click)="removeNoImgPhrase(i)" type="button">
              <nb-icon icon="minus"></nb-icon>
            </button>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
  <div class="form-control-group pb-5 pt-3">
    <button nbButton [status]="'success'" type="submit" class="create-button"
            [disabled]="loading || invForm.invalid"
            [nbSpinner]="loading" nbSpinnerStatus="success">
      {{submitText}}
    </button>
  </div>
</form>
