import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dlr-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {

  constructor() { }
}
