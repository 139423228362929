import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';
import {filter} from 'rxjs/operators';
import {AngularFireAnalytics} from '@angular/fire/analytics';
import {environment} from '@env';

declare const ga: any;

@Injectable()
export class AnalyticsService {
  private enabled = true;

  constructor(private location: Location, private router: Router, private analytics: AngularFireAnalytics) {
    if (environment.local) {
      this.enabled = false;
    }
    this.enabled = true;
  }

  trackEvent(eventName: string, params?: {}) {
    if (this.enabled) {
      return this.analytics.logEvent(eventName, params);
    }
  }

  setUserProperties(properties: {}) {
    if (this.enabled) {
      return this.analytics.setUserProperties(properties);
    }
  }
}
