import {State, Action, Selector, StateContext, Store} from '@ngxs/store';
import {LoggerService} from '@app/service/logger/logger.service';
import {ToastService} from '@app/service/util/toast.service';
import {AddSignupToken, EditSignupToken, GetAllTokens} from '@shared/state/signup/signup.actions';
import {SignupService} from '@shared/service/signup.service';
import {tap} from 'rxjs/operators';
import {ServerResponse} from '@shared/model';

export interface SignupStateModel {
  signupTokensInfo: any;
  loaded: boolean;
}

@State<SignupStateModel>({
  name: 'signup',
  defaults: {
    signupTokensInfo: [],
    loaded: false
  }
})
export class SignupState {
  duration = 10000;

  constructor(private logger: LoggerService, private signup: SignupService, private store: Store,
              private toast: ToastService) {
  }

  @Selector()
  static getSignUpTokensInfo(state: SignupStateModel) {
    return state.signupTokensInfo;
  }

  @Selector()
  static getSignupLoaded(state: SignupStateModel) {
    return state.loaded;
  }

  @Action(GetAllTokens)
  getAllTokens({getState, patchState}: StateContext<SignupStateModel>) {
    return this.signup.getSignUpsData()
      .pipe(tap(res => {
        const info = res;
        patchState({
          signupTokensInfo: info,
          loaded: true
        });
      }));
  }

  @Action(AddSignupToken)
  addSignUpToken({getState, patchState}: StateContext<SignupStateModel>, {payload}: AddSignupToken) {
    return this.signup.createSignupToken(payload)
      .then((res: ServerResponse) => {
        this.toast.response(res.type, res.message);
      })
      .catch((err) => {
        this.toast.response(err.type, err.message);
        this.logger.error('ERROR RES', err);
      });
  }

  @Action(EditSignupToken)
  editSignUpToken({getState, patchState}: StateContext<SignupStateModel>, {payload}: EditSignupToken) {
    return this.signup.editSignupToken(payload)
      .then((res: ServerResponse) => {
        this.toast.response(res.type, res.message);
      })
      .catch((err) => {
        this.toast.response(err.type, err.message);
        this.logger.error('ERROR RES', err);
      });
  }
}
